import Modalheader from "../../../components/header";
import useModal from "../../../useModal";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import StyledInput from "../../../../styledInput/StyledInput";
import { useState } from "react";
import Button from "../../../../button";
import { validateEmail } from "../../../../../utils/validator";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";

const ModalAddTeam = () => {
  const { toggle } = useModal();
  const { setModalDataReturn } = UseContext();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameError, setLastnameError] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [errorRole, setErrorRole] = useState(false);
  const [roleList, setRoleList] = useState([
    {
      idRole: 200,
      name: "Observateur",
      checked: true,
    },
    {
      idRole: 205,
      name: "Manageur",
      checked: false,
    },
    {
      idRole: 210,
      name: "Direction",
      checked: false,
    },
  ]);

  const handleSubmit = () => {
    if (email && firstname && lastname) {
      setFirstnameError(false);
      setLastnameError(false);
      if (!validateEmail(email)) {
        setEmailError("Veuillez entrer une adresse e-mail valide.");
      } else {
        setEmailError(false);
        setIsWaiting(true);

        if (roleList.find((role) => role.checked)?.idRole) {
          ApiDatabase.postUserInvite(
            {
              email,
              firstname,
              lastname,
              role: roleList.find((role) => role.checked).idRole,
            },
            (data) => {
              setModalDataReturn(data);
              setIsWaiting(false);
              toggle();
            },
            (err) => {
              if (err?.response?.data?.message === "User already exists") {
                setEmailError("Cet utilisateur existe déjà.");
              }
              setIsWaiting(false);
            },
          );
        } else {
          setErrorRole("Veuillez sélectionner un rôle.");
        }
      }
    } else {
      if (!email) setEmailError("Veuillez entrer une adresse e-mail.");
      else setEmailError(false);
      if (!firstname) setFirstnameError("Veuillez entrer un prénom.");
      else setFirstnameError(false);
      if (!lastname) setLastnameError("Veuillez entrer un nom.");
      else setLastnameError(false);
    }
  };

  const updateRolesList = (updatedRole) => {
    const value = Number(updatedRole.target.value);
    setRoleList((prevData) =>
      prevData.map((role) => ({
        ...role,
        checked: role.idRole === value,
      })),
    );
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Inviter par e-mail
      </Modalheader>
      <ModalBody>
        <div className="flex flex-col gap-6 w-full">
          <StyledInput
            type={"text"}
            label={"E-mail"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={emailError}
          />
          <div className={"flex gap-6 w-full"}>
            <div className={"w-full"}>
              <StyledInput
                type={"text"}
                label={"Prénom"}
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                error={firstnameError}
              />
            </div>
            <div className={"w-full"}>
              <StyledInput
                type={"text"}
                label={"Nom"}
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                error={lastnameError}
              />
            </div>
          </div>
          <StyledInput
            type={"select"}
            label={"Rôle"}
            value={roleList.find((role) => role.checked)?.idRole || ""}
            list={roleList.map((role) => {
              return {
                value: role.idRole,
                label: role.name,
                checked: role.checked,
              };
            })}
            onChange={(updatedRole) => updateRolesList(updatedRole)}
            error={errorRole}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color={"SECONDARY"} size={"LG"} onClick={toggle}>
          Annuler
        </Button>
        {isWaiting ? (
          <Button color={"DISABLED"} size={"LG"}>
            Confirmation ...
          </Button>
        ) : (
          <Button color={"PRIMARY"} size={"LG"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalAddTeam;
