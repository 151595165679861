import PageContainer from "../components/pageContainer/PageContainer";
import SidebarSettings from "../components/sidebars/SidebarSettings";
import { useEffect, useState } from "react";
import ApiDatabase from "../server";
import { Building, Warning } from "../assets/icons";
import { formatTimeDifference } from "../utils/Date";
import StyledInput from "../components/styledInput/StyledInput";
import useTitle from "../hooks/TitleManager";
import UseContext from "../hooks/useContext";

const SettingsCompanies = () => {
  useTitle({ title: "Paramètres - Établissements" });
  const { companies, setCompanies } = UseContext();
  const [isWaiting, setIsWaiting] = useState(true);

  useEffect(() => {
    setIsWaiting(true);
    ApiDatabase.getCompaniesInfosEmployees(
      {},
      (data) => {
        setCompanies(data.companies);
        setIsWaiting(false);
      },
      (err) => {
        setIsWaiting(false);
        console.log(err);
      },
    );
  }, [setCompanies]);

  const updateEmployeesList = (companyId, updatedEmployees) => {
    const updatedCompanies = companies.map((company) =>
      company._id === companyId
        ? {
            ...company,
            employees: company.employees?.map((employee) => {
              const updatedEmployee = updatedEmployees.find(
                (updatedEmployee) => updatedEmployee.value === employee._id,
              );

              return updatedEmployee
                ? { ...employee, checked: updatedEmployee.checked }
                : employee;
            }),
          }
        : company,
    );
    setCompanies(updatedCompanies);

    ApiDatabase.putCompaniesInfosEmployees(
      {
        idCompany: companyId,
        employees: updatedCompanies
          .find((company) => company._id === companyId)
          .employees.map((employee) => {
            return {
              idUser: employee._id,
              checked: employee.checked,
            };
          }),
      },
      (data) => {
        // console.log(data);
      },
      (err) => {
        // console.log(err);
      },
    );
  };

  return (
    <PageContainer type={"settings_companies"}>
      <div className={"flex gap-6 h-full"}>
        <SidebarSettings />
        <div
          className={
            "w-full h-full p-6 overflow-y-auto border rounded-lg flex flex-col gap-4"
          }
        >
          <div className={"font-black text-xl"}>Établissements</div>
          <div className={"flex flex-col gap-4"}>
            {isWaiting ? (
              <>
                {[1, 2, 3, 4].map((item, index) => (
                  <div
                    key={index}
                    className={
                      "flex justify-between gap-4 items-center px-4 py-3 rounded-lg border w-full animate-pulse"
                    }
                  >
                    <div className={"flex gap-2 items-center"}>
                      <div className={"h-10 bg-gray-200 rounded w-10"}></div>
                      <div className={"flex flex-col gap-1"}>
                        <div className={"h-4 bg-gray-200 rounded w-20"}></div>
                        <div className={"h-4 bg-gray-200 rounded w-60"}></div>
                      </div>
                    </div>
                    <div className={"flex w-56"}>
                      <div className={"h-12 bg-gray-200 rounded w-full"}></div>
                    </div>
                  </div>
                ))}
              </>
            ) : companies.length > 0 ? (
              companies.map((company, index) => (
                <div
                  key={index}
                  className={
                    "flex justify-between gap-4 items-center px-4 py-3 rounded-lg border w-full"
                  }
                >
                  <div className={"flex gap-2 items-center"}>
                    <div
                      className={
                        "bg-gray-100 text-gray-600 rounded-full w-10 h-10 flex justify-center items-center"
                      }
                    >
                      <Building wh={20} color={"#374151"} />
                    </div>
                    <div className={"flex flex-col"}>
                      {company.companyName}
                      {company.lastSyncDate ? (
                        <div className={"ft-sm text-gray-500"}>
                          Dernière synchronisation : il y a{" "}
                          {formatTimeDifference(
                            new Date(
                              new Date().getTime() -
                                new Date(company.lastSyncDate).getTime(),
                            ),
                          )}
                          {company.badgerName ? ` (${company.badgerName})` : ""}
                        </div>
                      ) : (
                        <div
                          className={
                            "ft-sm text-red-500 flex gap-1 items-center"
                          }
                        >
                          <Warning wh={20} color={"#EF4444"} />
                          Badgeuse non connectée
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={"flex w-56"}>
                    <StyledInput
                      type={"selectMultipleCheckbox"}
                      value={`${company.employees?.filter((employee) => employee.checked).length} ${company.employees?.filter((employee) => employee.checked).length === 1 ? "employé" : "employés"}`}
                      list={company.employees?.map((employee) => {
                        return {
                          value: employee._id,
                          label: employee.firstname + " " + employee.lastname,
                          checked: employee.checked,
                        };
                      })}
                      onChange={(updatedEmployees) =>
                        updateEmployeesList(company._id, updatedEmployees)
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className={"ft-lg text-gray-500"}>Aucun établissement</div>
            )}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default SettingsCompanies;
