import React from "react";
import ProfileDefault from "../../assets/images/profil.svg";

const AvatarUser = ({ size, image }) => {
  const SIZE_IMG = {
    XS:
      "overflow-hidden flex flex-wrap justify-center w-8 h-8 bg-gray-100 border" +
      " border-gray-100 shadow-sm rounded-full drop-shadow-sm",
    SM:
      "overflow-hidden flex flex-wrap justify-center w-10 h-10 bg-gray-100 border" +
      " border-gray-100 shadow-sm rounded-full drop-shadow-sm",
    MD:
      "overflow-hidden flex flex-wrap justify-center w-16 h-16 bg-gray-100 border" +
      " border-gray-100 shadow-sm rounded-lg drop-shadow-sm",
    LG:
      "overflow-hidden flex flex-wrap justify-center w-24 h-24 bg-gray-100 border" +
      " border-gray-100 shadow-sm rounded-md drop-shadow-sm",
    XL:
      "overflow-hidden flex flex-wrap justify-center w-48 h-48 bg-gray-100 border" +
      " border-gray-100 shadow-sm rounded-md drop-shadow-sm",
  };
  const sizeClassName = SIZE_IMG[size || "MD"];
  return (
    <div className={sizeClassName}>
      {image ? (
        <img
          src={image}
          alt="..."
          className="shadow rounded-full max-w-full h-auto align-middle border-none"
        />
      ) : (
        <img
          src={ProfileDefault}
          alt="..."
          className=" w-full h-auto align-middle border-none rounded-full "
        />
      )}
    </div>
  );
};

export default AvatarUser;
