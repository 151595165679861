import React from "react";
import ReactDOM from "react-dom";
import UseContext from "../../../hooks/useContext";
import SlideOverStructure from "../component/structure";
import DataSlideOver from "../dataSlideOver";

const Index = () => {
  const { slideOverShow } = UseContext();

  if (!slideOverShow) return null;

  return ReactDOM.createPortal(
    <SlideOverStructure isShowing={slideOverShow}>
      <DataSlideOver />
    </SlideOverStructure>,
    document.getElementById("root-portal"),
  );
};

export default Index;
