import React, { forwardRef } from "react";
import { Interrogation } from "../../assets/icons";

const Banner = forwardRef(({ type, children, ...props }, ref) => (
  <>
    <div
      ref={ref}
      className={` ${body[type]} ${type.includes("disabled") ? "" : "border px-4"}  py-3  rounded `}
      role="alert"
    >
      <div className="flex  items-center justify-start  gap-2">
        <div className="flex">
          <Interrogation
            color={InterrogationColors[type]}
            wh="24"
            strokeW="2"
          ></Interrogation>
        </div>
        <p className={` ${content[type]} block sm:inline text-sm`}>
          {children}
        </p>
      </div>
    </div>
  </>
));
const InterrogationColors = {
  info: "#60A5FA",
  warning: "#FFA43D",
  alert: "#F87171",
  disabledInfo: "#60A5FA",
};

const body = {
  info: `bg-blue-50`,
  disabledInfo: "",
  warning: `bg-yellow-50`,
  alert: `bg-red-50`,
};
const content = {
  info: `text-blue-700`,
  disabledInfo: `text-blue-700 underline underline-offset-2`,
  warning: `text-yellow-700`,
  alert: `text-red-700`,
};
export default Banner;
