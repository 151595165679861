import Button from "../button";
import { Calendar, ExternalLink, Import, LoopIcon } from "../../assets/icons";
import { useEffect, useState } from "react";
import useSlideOver from "../slideOver/useSlideOver";
import StyledInput from "../styledInput/StyledInput";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import { forceDateToUtc0 } from "../../utils/Date";
registerLocale("fr", fr);

const NavBarHourlyTracking = ({
  dateSelect,
  setDateSelect,
  dateList,
  dateListGlobal,
  companySelected,
  setCompanySelected,
  companyList,
  setCompanyList,
  interimAgencyList,
  setInterimAgencyList,
  interimAgencySelected,
  setInterimAgencySelected,
  interimSectorList,
  setInterimSectorList,
  interimSectorSelected,
  setInterimSectorSelected,
  search,
  setSearch,
  missionStatusSelected,
  setMissionStatusSelected,
  missionStatusList,
  setMissionStatusList,
}) => {
  const { toggleSlideOver } = useSlideOver();
  const [dateSelected, setDateSelected] = useState(dateSelect);
  const [isDateListNow, setIsDateListNow] = useState(false);
  const isShowImportExport = [225, 215, 210].includes(
    Number(localStorage.getItem("rUser")) || 0,
  );

  useEffect(() => {
    setDateSelected(dateSelect);
  }, [dateSelect]);

  useEffect(() => {
    const currentDateISOString = new Date().toISOString().split("T")[0];
    const dateSelectISOString = dateSelect
      ? new Date(dateSelect).toISOString().split("T")[0]
      : null;

    const isDateSelectNotInList = !dateListGlobal.some(
      (date) =>
        new Date(date).toISOString().split("T")[0] === dateSelectISOString,
    );

    const isCurrentDateInDateList = dateListGlobal.some(
      (date) =>
        new Date(date).toISOString().split("T")[0] === currentDateISOString,
    );

    setIsDateListNow(isCurrentDateInDateList);

    if (
      !dateSelectISOString ||
      dateSelectISOString === currentDateISOString ||
      isDateSelectNotInList
    ) {
      if (dateListGlobal.length > 0) {
        const nearestDate = findNearestDate(new Date(), dateListGlobal);
        if (new Date(nearestDate).getTime() !== dateSelect.getTime())
          setDateSelect(new Date(nearestDate));
      }
    }
  }, [
    companySelected,
    dateListGlobal,
    setDateSelect,
    setCompanySelected,
    dateSelect,
  ]);

  const findNearestDate = (targetDate, dateList) => {
    const targetDateUTC = new Date(targetDate.toISOString().split("T")[0]);

    return dateList.reduce((nearestDate, date) => {
      const dateUTC = new Date(date);
      dateUTC.setUTCHours(0, 0, 0, 0);

      const diff = Math.abs(targetDateUTC - dateUTC);
      const minDiff = Math.abs(targetDateUTC - new Date(nearestDate));

      return diff < minDiff ? date : nearestDate;
    }, dateList[0]);
  };

  const updateCompaniesList = (updatedCompany) => {
    const updatedCompanies = [];
    for (let i = 0; i < companyList.length; i++) {
      for (let j = 0; j < updatedCompany.length; j++) {
        if (companyList[i].idCompany === updatedCompany[j].value) {
          updatedCompanies.push({
            ...companyList[i],
            checked: updatedCompany[j].checked,
          });
        }
      }
    }
    setCompanyList(updatedCompanies);
    setCompanySelected({
      idCompany: updatedCompanies.find((company) => company.checked).idCompany,
      name: updatedCompanies.find((company) => company.checked).name,
      syncDate: updatedCompanies.find((company) => company.checked).syncDate,
      badgerName: updatedCompanies.find((company) => company.checked)
        .badgerName,
    });
  };

  const updateInterimAgenciesList = (updatedInterimAgency) => {
    const updatedInterimAgencies = [];

    for (let i = 0; i < interimAgencyList.length; i++) {
      for (let j = 0; j < updatedInterimAgency.length; j++) {
        if (interimAgencyList[i].name === updatedInterimAgency[j].value) {
          updatedInterimAgencies.push({
            ...interimAgencyList[i],
            checked: updatedInterimAgency[j].checked,
          });
        }
      }
    }

    setInterimAgencyList(updatedInterimAgencies);

    const selectedInterimAgencies = updatedInterimAgencies.filter(
      (interimAgency) => interimAgency.checked,
    );

    setInterimAgencySelected(selectedInterimAgencies);
  };

  const updateInterimSectorsList = (updatedInterimSector) => {
    const updatedInterimSectors = [];

    for (let i = 0; i < interimSectorList.length; i++) {
      for (let j = 0; j < updatedInterimSector.length; j++) {
        if (interimSectorList[i].name === updatedInterimSector[j].value) {
          updatedInterimSectors.push({
            ...interimSectorList[i],
            checked: updatedInterimSector[j].checked,
          });
        }
      }
    }

    setInterimSectorList(updatedInterimSectors);

    const selectedInterimSectors = updatedInterimSectors.filter(
      (interimSector) => interimSector.checked,
    );

    setInterimSectorSelected(selectedInterimSectors);
  };

  const updateMissionStatusList = (updateMissionStatus) => {
    const updatedMissionsStatus = [];

    for (let i = 0; i < missionStatusList.length; i++) {
      for (let j = 0; j < updateMissionStatus.length; j++) {
        if (missionStatusList[i].type === updateMissionStatus[j].value) {
          updatedMissionsStatus.push({
            ...missionStatusList[i],
            checked: updateMissionStatus[j].checked,
          });
        }
      }
    }

    setMissionStatusList(updatedMissionsStatus);

    const selectedMissionStatus = updatedMissionsStatus.filter(
      (missionStatus) => missionStatus.checked,
    );

    setMissionStatusSelected(selectedMissionStatus);
  };

  const exportMissions = () => {
    if (companySelected) {
      toggleSlideOver("export_missions");
    }
  };

  return (
    <nav className={"w-full flex justify-between items-center min-h-[45px]"}>
      <div className={"flex gap-2 items-center text-sm"}>
        <Button
          color={
            !isDateListNow ||
            dateSelected.toISOString().split("T")[0] ===
              new Date().toISOString().split("T")[0]
              ? "DISABLED"
              : "SECONDARY"
          }
          size={"SMI"}
          onClick={
            isDateListNow &&
            dateSelected.toISOString().split("T")[0] !==
              new Date().toISOString().split("T")[0]
              ? () => setDateSelect(new Date())
              : undefined
          }
        >
          Aujourd'hui
        </Button>
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={forceDateToUtc0(dateSelect)}
            onChange={(date) => setDateSelect(date)}
            locale="fr"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperPlacement="bottom"
            className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center"
            customInput={
              <Button color={"SECONDARY"} size={"SMI"}>
                <Calendar wh={16} color={"#374151"} />
              </Button>
            }
            includeDates={dateList}
          />
        </div>
        {companyList.length > 1 && (
          <div className={"max-w-[150px] text-sm"}>
            <StyledInput
              type={"selectMultipleRadio"}
              value={companySelected?.name || ""}
              list={companyList.map((company) => {
                return {
                  value: company.idCompany,
                  label: company.name,
                  checked: company.checked,
                };
              })}
              onChange={(updatedCompany) => updateCompaniesList(updatedCompany)}
              isSearchable={true}
            />
          </div>
        )}
        {interimAgencyList.length > 1 && (
          <div className={"max-w-[150px] text-sm"}>
            <StyledInput
              type={"selectMultipleCheckbox"}
              value={
                interimAgencySelected.length > 0
                  ? interimAgencySelected
                      .map((agency) => agency.name)
                      .join(", ")
                  : "Agence"
              }
              list={interimAgencyList.map((interimAgency) => {
                return {
                  value: interimAgency.name,
                  label: interimAgency.name,
                  checked: interimAgency.checked,
                };
              })}
              onChange={(updatedInterimAgency) =>
                updateInterimAgenciesList(updatedInterimAgency)
              }
              isSearchable={true}
            />
          </div>
        )}
        {interimSectorList.length > 1 && (
          <div className={"max-w-[150px] text-sm"}>
            <StyledInput
              type={"selectMultipleCheckbox"}
              value={
                interimSectorSelected.length > 0
                  ? interimSectorSelected
                      .map((sector) => sector.name)
                      .join(", ")
                  : "Secteurs"
              }
              list={interimSectorList.map((interimSector) => {
                return {
                  value: interimSector.name,
                  label: interimSector.name,
                  checked: interimSector.checked,
                };
              })}
              onChange={(updatedInterimSector) =>
                updateInterimSectorsList(updatedInterimSector)
              }
              isSearchable={true}
            />
          </div>
        )}
        {missionStatusList.length > 1 && (
          <div className={"max-w-[150px] text-sm"}>
            <StyledInput
              type={"selectMultipleCheckbox"}
              value={
                missionStatusSelected.length > 0
                  ? missionStatusSelected
                      .map((status) => status.name)
                      .join(", ")
                  : "État"
              }
              list={missionStatusList.map((missionStatus) => {
                return {
                  value: missionStatus.type,
                  label: missionStatus.name,
                  checked: missionStatus.checked,
                };
              })}
              onChange={(updatedMissionStatus) =>
                updateMissionStatusList(updatedMissionStatus)
              }
            />
          </div>
        )}
        <div className={"w-[213px] text-sm"}>
          <StyledInput
            type={"text"}
            placeholder={"Rechercher..."}
            imgFront={<LoopIcon wh={16} color={"#6B7280"} />}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      {isShowImportExport && (
        <div className={"flex gap-2 items-center"}>
          <Button
            color={"PRIMARY"}
            size={"SMI"}
            onClick={() => toggleSlideOver("import_missions")}
          >
            <div className={"flex gap-1 items-center"}>
              <Import wh={16} color={"#FFFFFF"} />
              Importer
            </div>
          </Button>
          {companySelected ? (
            <div className={"flex flex-col"}>
              <div className={"flex justify-end"}>
                <div className={"w-fit"}>
                  <Button
                    color={"SECONDARY"}
                    size={"SM"}
                    onClick={exportMissions}
                  >
                    <div className={"flex gap-1 items-center"}>
                      <ExternalLink wh={16} color={"#374151"} />
                      Export
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <Button color={"DISABLED"} size={"SMI"}>
              <div className={"flex gap-1 items-center"}>
                <ExternalLink wh={16} color={"#9CA3AF"} />
                Export
              </div>
            </Button>
          )}
        </div>
      )}
    </nav>
  );
};

export default NavBarHourlyTracking;
