import { dateLong } from "../../utils/Date";
import Button from "../button";
import {
  Cutlery,
  Entry,
  Exit,
  InformationCircle,
  Pause,
  Pencil,
  Play,
  WarningCircle,
} from "../../assets/icons";
import useSlideOver from "../slideOver/useSlideOver";
import { useLocation, useParams } from "react-router-dom";
import { durationInDecimals, floatToTime } from "../../utils/Time";
import Badges from "../badges";
import { useRef, useEffect } from "react";

const MissionTimeSheet = ({ mission, timeCut, isWaiting }) => {
  const { toggleSlideOver } = useSlideOver();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const date = queryParams.get("date");
  const isShowEditTempWorker = [225, 215, 210, 205].includes(
    Number(localStorage.getItem("rUser")) || 0,
  );

  const todayRef = useRef(null);

  useEffect(() => {
    if (todayRef.current) {
      todayRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [mission?.date, date]);

  return (
    <>
      {isWaiting ? (
        <div className={"w-full flex flex-col gap-4 animate-pulse"}>
          <div className={"flex justify-between items-center gap-1"}>
            <div className={"font-bold capitalize"}>
              <div className={"h-4 bg-gray-200 rounded w-60"}></div>
            </div>
            <Button size={"SMI"} color={"SECONDARY"}>
              <div className={"h-6 bg-gray-200 rounded w-6"}></div>
            </Button>
          </div>
          <div className={"flex flex-col border rounded"}>
            {[1, 2].map((i, index) => (
              <div
                key={index}
                className={`flex justify-between items-center p-4 ${index !== 0 && "border-t"}`}
              >
                <div className={"flex gap-6 items-center"}>
                  <div className={"h-10 bg-gray-200 rounded w-10"}></div>
                  <div className={"h-4 bg-gray-200 rounded w-14"}></div>
                </div>
                <div className={"flex justify-end gap-6 items-center"}>
                  <div className={"h-4 bg-gray-200 rounded w-14"}></div>
                  <div className={"h-10 bg-gray-200 rounded w-10"}></div>
                </div>
              </div>
            ))}
          </div>
          <div className={"border rounded px-3.5 py-2.5 w-fit"}>
            <div className={"h-4 bg-gray-200 rounded w-32"}></div>
          </div>
        </div>
      ) : (
        <div className={"w-full flex flex-col gap-4"}>
          <div className={"flex justify-between items-center gap-1"}>
            <div
              className={"font-bold capitalize"}
              ref={
                date
                  ? new Date(mission.date).toDateString() ===
                    new Date(date).toDateString()
                    ? todayRef
                    : null
                  : null
              }
            >
              {dateLong(new Date(mission.date))}
            </div>
            <div className={"flex gap-1 items-center"}>
              {mission.isFlexibilityDate && (
                <Badges color={"PRIMARY"}>
                  <div className={"flex gap-1"}>
                    Souplesse
                    <InformationCircle wh={16} color={"#374151"} />
                  </div>
                </Badges>
              )}
              {isShowEditTempWorker &&
                (new Date(mission.date).getTime() > new Date() ? (
                  <Button size={"SMI"} color={"DISABLED"}>
                    <Pencil wh={20} color={"#9CA3AF"} />
                  </Button>
                ) : (
                  <Button
                    size={"SMI"}
                    color={"SECONDARY"}
                    onClick={() =>
                      toggleSlideOver("edit_time_sheets", {
                        idTimeSheet: mission.idTimeSheet,
                        idMission: id,
                        dateMission: mission.date,
                      })
                    }
                  >
                    <Pencil wh={20} color={"#374151"} />
                  </Button>
                ))}
            </div>
          </div>
          <div className={"flex flex-col"}>
            {mission.markups.length > 0 ? (
              <div className={"flex flex-col rounded-lg border"}>
                {mission.markups.map((markup, index) => {
                  const filteredMarkup = Object.entries(markup).filter(
                    ([key, value]) => key === "start" || key === "end",
                  );

                  return filteredMarkup.map(([key, value], i) => {
                    if (value) {
                      const getType = () => {
                        if (key === "start") {
                          if (index === 0) {
                            return {
                              icon: (
                                <div className={"rounded-full bg-blue-500 p-2"}>
                                  <Entry wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Entrée",
                            };
                          } else if (
                            mission.markups[index - 1]?.end
                              ? durationInDecimals(
                                  mission.markups[index - 1].end,
                                  value,
                                ) <= timeCut
                              : false
                          ) {
                            return {
                              icon: (
                                <div
                                  className={"rounded-full bg-yellow-500 p-2"}
                                >
                                  <Cutlery wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Fin de pause",
                            };
                          } else {
                            return {
                              icon: (
                                <div
                                  className={"rounded-full bg-yellow-500 p-2"}
                                >
                                  <Play wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Fin de coupure",
                            };
                          }
                        } else if (key === "end") {
                          if (index === mission.markups.length - 1) {
                            return {
                              icon: (
                                <div className={"rounded-full bg-blue-500 p-2"}>
                                  <Exit wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Sortie",
                            };
                          } else if (
                            mission.markups[index + 1]?.start
                              ? durationInDecimals(
                                  value,
                                  mission.markups[index + 1].start,
                                ) <= timeCut
                              : false
                          ) {
                            return {
                              icon: (
                                <div
                                  className={"rounded-full bg-yellow-500 p-2"}
                                >
                                  <Cutlery wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Début de pause",
                            };
                          } else {
                            return {
                              icon: (
                                <div
                                  className={"rounded-full bg-yellow-500 p-2"}
                                >
                                  <Pause wh={20} color={"#FFFFFF"} />
                                </div>
                              ),
                              name: "Début de coupure",
                            };
                          }
                        }
                        return { icon: "", name: "" };
                      };

                      const type = getType();

                      return (
                        <div
                          key={i}
                          className={`flex justify-between items-center p-4 ${(i !== 0 || index !== 0) && "border-t"}`}
                        >
                          <div className={"flex gap-6 items-center"}>
                            {type && <div>{type.icon}</div>}
                            {type && <div>{type.name}</div>}
                          </div>
                          <div
                            className={"flex justify-end gap-6 items-center"}
                          >
                            {value && <div>{value}</div>}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  });
                })}
              </div>
            ) : (
              <div className={"flex gap-4 items-center"}>
                <div
                  className={
                    "p-2 flex justify-center items-center bg-red-50 rounded-full"
                  }
                >
                  <WarningCircle wh={20} color={"#EF4444"} />
                </div>
                <div className={"text-sm text-gray-500"}>
                  <div className={"font-bold"}>Aucun pointage disponible</div>
                  <div>L’intérimaire n’a pas pointé pour ce jour</div>
                </div>
              </div>
            )}
          </div>
          {mission.totalHours !== 0 && mission.markups.length > 0 && (
            <div className={"flex py-2.5 px-3.5 border rounded-lg w-fit"}>
              Total heures travaillées : {floatToTime(mission.totalHours)}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default MissionTimeSheet;
