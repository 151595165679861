module.exports.floatToTime = (number, type = "h") => {
  // Check sign of given number
  let sign = number >= 0 ? 1 : -1;

  // Set positive value of number of sign negative
  number = number * sign;

  // Separate the int from the decimal part
  let hour = Math.floor(number);
  let decpart = number - hour;

  let min = 1 / 60;
  // Round to nearest minute
  decpart = min * Math.round(decpart / min);

  let minute = Math.floor(decpart * 60) + "";

  if (minute.length < 2) {
    minute = "0" + minute;
  }

  // Add Sign in final result
  sign = sign === 1 ? "" : "-";

  // Concate hours and minutes
  return sign + hour + type + (minute !== "00" ? minute : "");
};

module.exports.durationInDecimals = (start, end) => {
  const startTime = new Date(`1970-01-01T${start}`);
  const endTime = new Date(`1970-01-01T${end}`);
  return (endTime - startTime) / 3600000;
};
