import { NavLink } from "react-router-dom";
import useTitle from "../hooks/TitleManager";

const Unauthorized = () => {
  useTitle({ title: "Accès non autorisé" });
  return (
    <div
      className={"w-full h-screen flex justify-center items-center text-center"}
    >
      <div className={"max-w-2xl flex flex-col gap-2"}>
        <div className={"flex justify-center"}>Accès non autorisé</div>
        <div>
          Vous n'êtes pas autorisé à accéder à cette page. Veuillez vous
          connecter avec les bonnes informations d'identification.
        </div>
        <NavLink to={"/"} className={"text-blue-700 underline"}>
          Retour
        </NavLink>
      </div>
    </div>
  );
};

export default Unauthorized;
