import PageContainer from "../components/pageContainer/PageContainer";
import SidebarSettings from "../components/sidebars/SidebarSettings";
import StyledInput from "../components/styledInput/StyledInput";
import { useEffect, useState } from "react";
import ApiDatabase from "../server";
import useTitle from "../hooks/TitleManager";

const SettingsTimeManagement = () => {
  useTitle({ title: "Paramètres - Gestion des heures & pointages" });
  const [timeCut, setTimeCut] = useState(0);
  const [isWaiting, setIsWaiting] = useState(false);

  let timeCutTimeout = null;

  useEffect(() => {
    setIsWaiting(true);
    ApiDatabase.getTimeCut(
      {},
      (res) => {
        setTimeCut(res.timeCut);
        setIsWaiting(false);
      },
      (err) => {
        console.log("err", err);
        setIsWaiting(false);
      },
    );
  }, [setTimeCut]);

  const timeCutChange = (e) => {
    setTimeCut(e.target.value);
    clearTimeout(timeCutTimeout);

    timeCutTimeout = setTimeout(() => {
      ApiDatabase.postUpdateTimeCut(
        { timeCut: e.target.value },
        (res) => {
          console.log("res", res);
        },
        (err) => {
          console.log("err", err);
        },
      );
    }, 1000);
  };

  return (
    <PageContainer type={"settings_time_management"}>
      <div className={"flex gap-6 h-full"}>
        <SidebarSettings />
        <div
          className={
            "w-full h-full p-6 overflow-y-auto border rounded-lg flex flex-col gap-4"
          }
        >
          <div className={"font-black text-xl"}>
            Gestion des heures & pointages
          </div>
          <div className={"py-3 px-4 flex justify-between gap-4 items-center"}>
            <div className={"flex flex-col gap-3"}>
              <div className={"flex flex-col"}>
                <div className={"font-bold"}>Indiquer les coupures</div>
                <div>
                  Autoriser le calcul des coupures dès qu’un temps de pause est
                  dépassé
                </div>
              </div>
              <div className={"flex flex-col max-w-[330px]"}>
                {isWaiting ? (
                  <div className={"animate-pulse"}>
                    <div className={"h-12 bg-gray-200 rounded w-full"}></div>
                  </div>
                ) : (
                  <StyledInput
                    type={"number"}
                    value={timeCut}
                    placeholder={"0"}
                    unit={"minutes"}
                    onChange={timeCutChange}
                  />
                )}
              </div>
            </div>
            <div className={"flex flex-col"}></div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default SettingsTimeManagement;
