import { useEffect, useState } from "react";
import { Warning } from "../../assets/icons";
import { formatTimeDifference } from "../../utils/Date";
import Pagination from "../pagination/Pagination";

const FooterHourlyTracking = ({
  syncDate,
  badgerName,
  currentPage,
  countPerPage,
  countTotal,
  totalPages,
  setCurrentPage,
}) => {
  const [lastSyncDate, setLastSyncDate] = useState();

  useEffect(() => {
    if (syncDate) {
      const timeDifference =
        new Date().getTime() - new Date(syncDate).getTime();
      const timeDifferenceDate = new Date(timeDifference);
      const timeDifferenceText = formatTimeDifference(timeDifferenceDate);
      setLastSyncDate(timeDifferenceText);
    }
  }, [syncDate]);

  return (
    <div className={"w-full flex justify-between items-center min-h-[36px]"}>
      <div className={""}>
        {syncDate ? (
          <div className={"text-sm text-gray-500"}>
            Dernière synchronisation : il y a {lastSyncDate}{" "}
            {badgerName ? `(${badgerName})` : ""}
          </div>
        ) : (
          <div className={"ft-sm text-red-500 flex gap-1 items-center"}>
            <Warning wh={20} color={"#EF4444"} />
            Badgeuse non connectée
          </div>
        )}
      </div>
      <div className={""}>
        <Pagination
          currentPage={currentPage}
          countPerPage={countPerPage}
          countTotal={countTotal}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default FooterHourlyTracking;
