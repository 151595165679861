import { useLocation, Navigate, Outlet } from "react-router-dom";

const useToken = () => {
  const token = localStorage.getItem("xsrfToken");
  return !!token;
};

const useRole = () => {
  return Number(localStorage.getItem("rUser")) || false;
};

const RequireAuth = ({ allowedRoles }) => {
  const rAuth = useRole();
  const tAuth = useToken();
  const location = useLocation();

  return allowedRoles?.includes(rAuth) ? (
    <Outlet />
  ) : tAuth ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    (window.location.href =
      process.env.REACT_APP_COMPANY_LOGIN +
      "?redirect=" +
      encodeURIComponent(location.pathname))
  );
};

export default RequireAuth;
