const TableActivities = ({ activity, isWaiting }) => {
  return (
    <>
      {isWaiting ? (
        <div className={`grid grid-cols-5 gap-8 px-4 py-3 text-sm`}>
          <div className={"flex flex-col justify-center"}>
            <div className={"h-4 bg-gray-200 rounded w-40"}></div>
          </div>
          <div className={"flex flex-col justify-center"}>
            <div className={"h-4 bg-gray-200 rounded w-30"}></div>
          </div>
          <div className={"flex justify-between items-center gap-1 col-span-2"}>
            <div className={"h-4 bg-gray-200 rounded w-56"}></div>
            <div className={"bg-gray-200 rounded w-9 h-9"}></div>
          </div>
          <div className={"flex flex-col justify-center"}>
            <div className={"h-4 bg-gray-200 rounded w-20"}></div>
            <div className={"h-4 bg-gray-200 rounded w-10"}></div>
          </div>
        </div>
      ) : (
        <div className={`grid grid-cols-5 gap-8 px-4 py-3 text-sm`}>
          <div className={"flex flex-col justify-center"}>{activity.date}</div>
          <div className={"flex flex-col justify-center"}>
            {activity.employee}
          </div>
          <div className={"flex flex-col justify-center col-span-2"}>
            {activity.title}
          </div>
          <div className={"flex flex-col justify-center"}>
            {activity.description}
          </div>
        </div>
      )}
    </>
  );
};

export default TableActivities;
