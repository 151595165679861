import React from 'react'

const ModalFooter = (props) => {
    return (
        <div className="flex items-center justify-end p-6 rounded-b gap-4">
            {props.children}

        </div>
    )
}

export default ModalFooter