import { Building, ChevronRight, CogIcon, Team } from "../../assets/icons";
import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ApiDatabase from "../../server";
import UseContext from "../../hooks/useContext";

const SidebarSettings = () => {
  const { nbEmployees, setNbEmployees, nbCompanies, setNbCompanies } =
    UseContext();
  const [sidebarElements, setSidebarElements] = useState([
    {
      type: "time-management",
      name: "Gestion des heures",
      path: "/settings/time-management",
      icon: <CogIcon wh={20} color={"#374151"} />,
      description: "Pointage & calculs",
      isActive: false,
    },
    {
      type: "team",
      name: "Équipe",
      path: "/settings/team",
      icon: <Team wh={20} color={"#374151"} />,
      description: `${nbEmployees > 1 ? `${nbEmployees} employés` : `${nbEmployees} employé`}`,
      isActive: false,
    },
    {
      type: "companies",
      name: "Établissements",
      path: "/settings/companies",
      icon: <Building wh={20} color={"#374151"} />,
      description: `${nbCompanies > 1 ? `${nbCompanies} établissements` : `${nbCompanies} établissement`}`,
      isActive: false,
    },
  ]);
  const currentPath = useLocation().pathname;

  useEffect(() => {
    if (currentPath) {
      setSidebarElements((prev) =>
        prev.map((element) => ({
          ...element,
          isActive: currentPath.includes(element.path),
        })),
      );
    }
  }, [currentPath]);

  useEffect(() => {
    ApiDatabase.getNbInSettings(
      {},
      (res) => {
        setNbEmployees(res.nbEmployees);
        setNbCompanies(res.nbCompanies);
      },
      (err) => {
        console.log(err);
      },
    );
  }, [setNbCompanies, setNbEmployees]);

  useEffect(() => {
    setSidebarElements((prev) =>
      prev.map((element) => {
        if (element.type === "team") {
          return {
            ...element,
            description: `${nbEmployees > 1 ? `${nbEmployees} employés` : `${nbEmployees} employé`}`,
          };
        }
        if (element.type === "companies") {
          return {
            ...element,
            description: `${nbCompanies > 1 ? `${nbCompanies} établissements` : `${nbCompanies} établissement`}`,
          };
        }
        return element;
      }),
    );
  }, [nbEmployees, nbCompanies]);

  return (
    <div className={"h-full max-w-[234px] w-full"}>
      {sidebarElements.map((element, index) => (
        <NavLink
          key={index}
          to={element.path}
          className={`w-full py-2 px-3 flex gap-3 items-center justify-between cursor-pointer rounded-lg ${element.isActive ? "bg-gray-100" : "hover:bg-gray-100"}`}
        >
          <div className={"flex gap-2"}>
            {element.icon}
            <div className={"flex flex-col"}>
              <div className={"text-sm"}>{element.name}</div>
              <div className={"text-xs"}>{element.description}</div>
            </div>
          </div>
          <div className={"flex justify-end"}>
            <ChevronRight wh={20} color={"#374151"} />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default SidebarSettings;
