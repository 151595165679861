import { ContextProvider } from "./context/Context";
import Routes from "./routes";
import Modal from "./components/modal/portal";
import SlideOver from "./components/slideOver/portal";
import { useBattery } from "react-use";
import { useEffect } from "react";

const App = () => {
  const battery = useBattery();

  useEffect(() => {
    const updateBatteryData = () => {
      const { isSupported, level, charging } = battery;

      if (isSupported) {
        const batteryInfo = {
          level: parseFloat((level * 100).toFixed(0)),
          charging,
        };

        localStorage.setItem("battery", JSON.stringify(batteryInfo));
      }
    };

    updateBatteryData();

    const intervalId = setInterval(updateBatteryData, 60000);

    return () => clearInterval(intervalId);
  }, [battery]);

  return (
    <ContextProvider>
      <Modal />
      <SlideOver />
      <Routes />
    </ContextProvider>
  );
};

export default App;
