import LogoutNavDesktop from "./navbarComponents/LogoutNavDesktop";
import Button from "../button";
import { ChevronLeft, ChevronRight } from "../../assets/icons";
import { useEffect, useState } from "react";
const { dateLong } = require("../../utils/Date");

const NavbarSite = ({ type, dateSelect, setDateSelect, dateList }) => {
  const [datePrev, setDatePrev] = useState(false);
  const [dateSelected, setDateSelected] = useState(dateSelect);
  const [dateNext, setDateNext] = useState(false);

  useEffect(() => {
    setDateSelected(dateSelect);
  }, [dateSelect]);

  useEffect(() => {
    if (dateSelected && dateList.length > 0) {
      const sortedDates = dateList.sort((a, b) => new Date(a) - new Date(b));

      const selectedIndex = sortedDates.findIndex(
        (date) =>
          new Date(date).toISOString().split("T")[0] >=
          new Date(dateSelected).toISOString().split("T")[0],
      );

      if (selectedIndex > 0) {
        setDatePrev(new Date(sortedDates[selectedIndex - 1]));
      } else {
        setDatePrev(null);
      }

      if (selectedIndex < sortedDates.length - 1) {
        setDateNext(new Date(sortedDates[selectedIndex + 1]));
      } else {
        setDateNext(null);
      }
    }
  }, [dateSelected, dateList]);

  const onPrevDateSelect = () => {
    if (datePrev) {
      setDateSelect(datePrev);
    }
  };

  const onNextDateSelect = () => {
    if (dateNext) {
      setDateSelect(dateNext);
    }
  };

  return (
    <nav
      className={`py-4 px-8 ${type !== "hourly_tracking" && type !== "activity" ? "border-b border-b-gray-100" : ""}`}
    >
      <div className={"flex justify-between items-center"}>
        <div className={"capitalize"}>
          {type === "hourly_tracking" ? (
            <div className={"flex gap-2.5 items-center"}>
              <div className={"flex gap-1"}>
                <Button
                  color={datePrev ? "SECONDARY" : "DISABLED"}
                  size={"SMI"}
                  onClick={() => onPrevDateSelect()}
                >
                  {datePrev ? (
                    <ChevronLeft wh={16} color={"#374151"} />
                  ) : (
                    <ChevronLeft wh={16} color={"#9CA3AF"} />
                  )}
                </Button>
                <Button
                  color={dateNext ? "SECONDARY" : "DISABLED"}
                  size={"SMI"}
                  onClick={() => onNextDateSelect()}
                >
                  {dateNext ? (
                    <ChevronRight wh={16} color={"#374151"} />
                  ) : (
                    <ChevronRight wh={16} color={"#9CA3AF"} />
                  )}
                </Button>
              </div>
              <div className={"text-2xl font-semibold"}>
                {dateLong(dateSelected)}
              </div>
            </div>
          ) : type === "activity" ? (
            <div className={"text-2xl font-semibold"}>Activité</div>
          ) : (
            <>{dateLong(new Date())}</>
          )}
        </div>
        <LogoutNavDesktop />
      </div>
    </nav>
  );
};

export default NavbarSite;
