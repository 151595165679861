import UseContext from "../../../hooks/useContext";
import SlideOverImportMission from "./data/slideOverImportMission/SlideOverImportMission";
import SlideOverUpdateTimeSheet from "./data/slideOverMission/SlideOverUpdateTimeSheet";
import SlideOverExportMission from "./data/slideOverExportMission/SlideOverExportMission";

const dataSlideOver = new Map();
dataSlideOver.set("import_missions", <SlideOverImportMission />);
dataSlideOver.set("edit_time_sheets", <SlideOverUpdateTimeSheet />);
dataSlideOver.set("export_missions", <SlideOverExportMission />);

const DataSlideOver = () => {
  const { slideOverType } = UseContext();
  return <>{dataSlideOver.get(slideOverType)}</>;
};

export default DataSlideOver;
