import UseContext from "../../hooks/useContext";

const useSlideOver = () => {
  const {
    slideOverShow,
    setSlideOverShow,
    setSlideOverType,
    setSlideOverData,
  } = UseContext();

  function toggleSlideOver(v, data = null) {
    setSlideOverType(v);
    setSlideOverShow(!slideOverShow);
    setSlideOverData(data);
  }

  return {
    toggleSlideOver,
  };
};

export default useSlideOver;
