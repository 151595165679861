import StyledInput from "../../../../styledInput/StyledInput";
import { useEffect, useState } from "react";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";

const SlideOverImportMissionAddCompany = ({
  companiesToAdd,
  companiesSelected,
  setCompaniesSelected,
}) => {
  const { userData } = UseContext();

  const [companiesSelect, setCompaniesSelect] = useState(
    companiesSelected
      ? companiesSelected.filter((company) => company.checked)
      : [],
  );
  const [companyEmployees, setCompanyEmployees] = useState(
    companiesSelected || [],
  );
  const [isWaitingTeams, setIsWaitingTeams] = useState(false);

  useEffect(() => {
    const companies = [];

    for (let i = 0; i < companiesSelect.length; i++) {
      let isExist = false;
      for (let j = 0; j < companyEmployees.length; j++) {
        if (companyEmployees[j].name === companiesSelect[i].name) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        companies.push({ ...companiesSelect[i], checked: false });
        //add companiesSelect[i] to companyEmployees
        setCompanyEmployees((prev) => [
          ...prev,
          {
            ...companiesSelect[i],
            checked: false,
          },
        ]);
      }
    }

    for (let i = 0; i < companyEmployees.length; i++) {
      let isExist = false;
      for (let j = 0; j < companiesSelect.length; j++) {
        if (companyEmployees[i].name === companiesSelect[j].name) {
          isExist = true;
          break;
        }
      }
      if (!isExist) {
        companies.push({ ...companyEmployees[i], checked: false });
      }
    }

    for (let i = 0; i < companyEmployees.length; i++) {
      for (let j = 0; j < companiesSelect.length; j++) {
        if (companyEmployees[i].name === companiesSelect[j].name) {
          let isExist = false;
          for (let k = 0; k < companies.length; k++) {
            if (companyEmployees[i].name === companies[k].name) {
              isExist = k;
              break;
            } else {
              isExist = false;
            }
          }
          if (!isExist) {
            companies.push({ ...companyEmployees[i], checked: true });
          } else {
            companies[isExist] = { ...companyEmployees[i], checked: true };
          }
        }
      }
    }

    setCompaniesSelected(companies);
  }, [companiesSelect, setCompaniesSelected, companyEmployees]);

  useEffect(() => {
    if (userData._id && Object.keys(companiesSelected).length === 0) {
      setIsWaitingTeams(true);
      ApiDatabase.getTeams(
        {},
        (data) => {
          if (data.teams) {
            setCompanyEmployees([]);
            setCompaniesSelect([]);
            for (let i = 0; i < companiesToAdd.length; i++) {
              setCompaniesSelect((prev) => {
                if (prev.includes(companiesToAdd[i].name)) {
                  return prev;
                }
                return [
                  ...prev,
                  {
                    name: companiesToAdd[i].name,
                    employees: companiesToAdd[i].employees || [],
                  },
                ];
              });

              setCompanyEmployees((prev) => {
                return [
                  ...prev,
                  {
                    name: companiesToAdd[i].name,
                    employees: data.teams.map((team) => {
                      return {
                        idUser: team._id,
                        firstname: team.firstname,
                        lastname: team.lastname,
                        checked: userData._id === team._id,
                      };
                    }),
                  },
                ];
              });
            }
          } else {
            setCompanyEmployees([]);
          }
          setIsWaitingTeams(false);
        },
        (err) => {
          console.log("err", err);
          setIsWaitingTeams(false);
        },
      );
    } else {
      /*if (!deepArrayCompare(companiesSelected, companiesSelect)) {
        setCompaniesSelect(companiesSelected);

        //only get companies with employees
        setCompanyEmployees(
          companiesSelected.filter((company) =>
            company.hasOwnProperty("employees"),
          ),
        );
      }*/
    }
  }, [userData, companiesToAdd, companiesSelected]);

  const updateEmployeesList = (companyName, updatedEmployees) => {
    setCompanyEmployees((prev) => {
      return prev.map((company) => {
        if (company.name === companyName) {
          return {
            ...company,
            employees: company.employees?.map((employee) => {
              return {
                ...employee,
                checked: updatedEmployees.find(
                  (e) => e.value === employee.idUser,
                ).checked,
              };
            }),
          };
        }
        return company;
      });
    });
  };

  return (
    <div className="flex flex-col gap-3">
      {isWaitingTeams ? (
        <>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <div
              key={index}
              className={
                "px-4 py-2 font-black rounded border flex justify-between items-center animate-pulse"
              }
            >
              <div className={"h-6 bg-gray-200 rounded w-32"}></div>
              <div className={"flex justify-end gap-2 items-center"}>
                <div className={"w-[250px]"}>
                  <div className={"h-12 bg-gray-200 rounded w-full"}> </div>
                </div>
                <div>
                  <div className={"h-8 bg-gray-200 rounded w-8"}></div>
                </div>
              </div>
            </div>
          ))}
        </>
      ) : (
        <>
          {companyEmployees.map((company, index) => (
            <div
              key={index}
              className={
                "px-4 py-2 font-black rounded border flex justify-between items-center"
              }
            >
              <div>{company.name}</div>
              <div className={"flex justify-end gap-2 items-center"}>
                <div className={"w-[250px] text-sm"}>
                  <StyledInput
                    type={"selectMultipleCheckbox"}
                    value={`${company.employees?.filter((employee) => employee.checked).length} ${company.employees?.filter((employee) => employee.checked).length === 1 ? "employé" : "employés"}`}
                    list={company.employees?.map((employee) => {
                      return {
                        value: employee.idUser,
                        label: employee.firstname + " " + employee.lastname,
                        checked: employee.checked,
                      };
                    })}
                    onChange={(updatedEmployees) =>
                      updateEmployeesList(company.name, updatedEmployees)
                    }
                  />
                </div>
                <div className={"cursor-pointer"}>
                  <StyledInput
                    type={"checkbox-round"}
                    checked={companiesSelect
                      .map((c) => c.name)
                      .includes(company.name)}
                    onChange={(e) => {
                      setCompaniesSelect((prev) => {
                        if (e.target.checked) {
                          // Check if the company is already in the state
                          const isCompanyExist = prev.some(
                            (c) => c.name === company.name,
                          );

                          if (!isCompanyExist) {
                            return [
                              ...prev,
                              {
                                name: company.name,
                                employees: company.employees,
                              },
                            ];
                          }
                        } else {
                          // Remove the company from the state
                          return prev.filter((c) => c.name !== company.name);
                        }

                        // If the company is already in the state and checked status is true, return unchanged state
                        return prev;
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default SlideOverImportMissionAddCompany;
