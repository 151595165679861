import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import React, { useState } from "react";
import ApiDatabase from "../../../../../server";

const ModalEditTempWorkerToken = () => {
  const { toggle } = useModal();
  const { modalData, setModalDataReturn } = UseContext();
  const [isLoading, setIsLoading] = useState(false);
  const [errorTimeOut, setErrorTimeOut] = useState("");

  const handleSubmit = () => {
    setIsLoading(true);
    setErrorTimeOut("");
    ApiDatabase.updateTempWorkerToken(
      { idTempWorker: modalData.idTempWorker },
      (data) => {
        setModalDataReturn({ tokenMission: data.tokenMission });
        setIsLoading(false);
        setErrorTimeOut("");
        toggle();
      },
      (err) => {
        setIsLoading(false);
        if (err.response && err.response.data && err.response.data.message) {
          if (err.response.data.message === "Too many requests") {
            setErrorTimeOut(
              `Vous pourrez régénérer un code PIN dans ${err.response.data.time} minutes`,
            );
          }
        }
        console.log(err);
      },
    );
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Générer un code pin
      </Modalheader>
      <ModalBody>
        <div className={"flex flex-col gap-2"}>
          <div className={"text-gray-500"}>
            Êtes-vous sûr de vouloir générer un nouveau code PIN ? Une fois le
            code PIN modifié, l'intérimaire devra utiliser le nouveau code PIN
            pour badger ses missions en cours et futures.
          </div>
          {errorTimeOut && (
            <div className={"text-red-500 text-sm"}>{errorTimeOut}</div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
          Annuler
        </Button>
        {isLoading ? (
          <Button size={"LG"} color={"DISABLED"}>
            Confirmation...
          </Button>
        ) : (
          <Button size={"LG"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalEditTempWorkerToken;
