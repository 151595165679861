import Button from "../button";
import {
  Calendar,
  ChevronLeft,
  ChevronRight,
  CogIcon,
  Help,
  PresentationIcon,
  WorkfullyLogo,
} from "../../assets/icons";
import Tooltip from "../tooltips/Tooltips";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import UseContext from "../../hooks/useContext";

const SidebarSite = () => {
  const { userData } = UseContext();
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [sidebarElements, setSidebarElements] = useState([
    {
      title: "Suivi horaire",
      icon: <Calendar wh={20} color={"#374151"} />,
      iconActive: <Calendar wh={20} color={"#D77A1E"} />,
      path: "/hourly-tracking",
      type: "content",
      isActive: true,
      allowRoles: [225, 215, 210, 205, 200],
    },
    {
      title: "Activité",
      icon: <PresentationIcon wh={20} color={"#374151"} />,
      iconActive: <PresentationIcon wh={20} color={"#D77A1E"} />,
      path: "/activity",
      type: "content",
      isActive: false,
      allowRoles: [225, 215, 210],
    },
    {
      title: "Aide",
      icon: <Help wh={20} color={"#374151"} />,
      iconActive: <Help wh={20} color={"#D77A1E"} />,
      path: "https://docs.google.com/document/d/1YQsrqNKgSR3q64lyCGCCkR-oVvC8d9tN4vZCrCOSUxk/edit?usp=sharing",
      pathOptions: { target: "_blank", rel: "noopener noreferrer" },
      type: "content",
      isActive: false,
      allowRoles: [225, 215, 210, 205, 200],
    },
    {
      title: "Paramètres",
      icon: <CogIcon wh={20} color={"#374151"} />,
      iconActive: <CogIcon wh={20} color={"#D77A1E"} />,
      path: "/settings",
      type: "footer",
      isActive: false,
      allowRoles: [225],
    },
  ]);
  const currentPath = useLocation().pathname;

  useEffect(() => {
    if (currentPath) {
      setSidebarElements((prev) =>
        prev.map((element) => ({
          ...element,
          isActive: currentPath.includes(element.path),
        })),
      );
    }
  }, [currentPath]);

  return (
    <div
      className={`px-5 py-8 flex flex-col h-full ${
        sidebarExpanded ? "w-64 transition-w-64" : "w-20 transition-w-20"
      } duration-700 gap-11 border-r border-r-gray-100 bg-gray-50`}
    >
      <div className={"flex justify-between gap-8 items-center"}>
        {sidebarExpanded && (
          <div>
            <WorkfullyLogo w={130} h={30} />
          </div>
        )}
        <Tooltip
          text={sidebarExpanded ? "Reduire" : "Agrandir"}
          placement="right"
        >
          <div>
            <Button
              color={"ICON"}
              size={"ICON"}
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
            >
              {sidebarExpanded ? (
                <ChevronLeft wh={20} color={"#374151"} />
              ) : (
                <ChevronRight wh={20} color={"#374151"} />
              )}
            </Button>
          </div>
        </Tooltip>
      </div>
      <div className={"flex-1 py-4 text-sm"}>
        {/* Render elements with type: "content" */}
        {sidebarElements
          .filter(
            (element) =>
              element.type === "content" &&
              element.allowRoles.includes(userData?.role || 215),
          )
          .map((element, key) => {
            if (element.path.startsWith("http")) {
              // Use an anchor tag for external links
              return (
                <a
                  href={element.path}
                  key={key}
                  target={element.pathOptions?.target || "_self"}
                  rel={element.pathOptions?.rel || ""}
                  className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${
                    element.isActive
                      ? "border-yellow-100 bg-yellow-50 text-yellow-600"
                      : "border-gray-50"
                  }`}
                >
                  <div>{element.icon}</div>
                  {sidebarExpanded && <div>{element.title}</div>}
                </a>
              );
            } else {
              // Use NavLink for internal links
              return (
                <NavLink
                  to={element.path}
                  key={key}
                  className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${
                    element.isActive
                      ? "border-yellow-100 bg-yellow-50 text-yellow-600"
                      : "border-gray-50"
                  }`}
                >
                  <div>
                    {element.isActive ? element.iconActive : element.icon}
                  </div>
                  {sidebarExpanded && <div>{element.title}</div>}
                </NavLink>
              );
            }
          })}
      </div>
      <div>
        {/* Render elements with type: "footer" */}
        {sidebarElements
          .filter(
            (element) =>
              element.type === "footer" &&
              element.allowRoles.includes(userData?.role || 215),
          )
          .map((element, key) => {
            return (
              <NavLink
                to={element.path}
                key={key}
                className={`w-full flex gap-4 items-center px-2 py-3 cursor-pointer rounded-lg border ${
                  element.isActive
                    ? "border-yellow-100 bg-yellow-50 text-yellow-600"
                    : "border-gray-50"
                }`}
              >
                <div>
                  {element.isActive ? element.iconActive : element.icon}
                </div>
                {sidebarExpanded && <div>{element.title}</div>}
              </NavLink>
            );
          })}
      </div>
    </div>
  );
};

export default SidebarSite;
