import { useEffect, useState } from "react";
import StyledInput from "../../../../styledInput/StyledInput";

const SlideOverImportMissionImportFile = ({
  fileToSend,
  setFileToSend,
  errors,
}) => {
  const [fileUpload, setFileUpload] = useState();
  const [errorFileUpload, setErrorFileUpload] = useState("");
  const [titleFile, setTitleFile] = useState();
  const acceptFileType = [
    {
      title: "CSV",
      name: "text/csv",
    },
  ];
  const sizeFile = {
    title: "5Mo",
    size: 5 * 1024 * 1024,
  };

  const handleAddFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file) {
      const acceptedTypes = acceptFileType.map((type) => type.name);
      if (!acceptedTypes.includes(file.type)) {
        setErrorFileUpload("Type de fichier non autorisé.");
        return;
      }

      if (file.size > sizeFile.size) {
        setErrorFileUpload("La taille du fichier dépasse 5 Mo.");
        return;
      }

      setErrorFileUpload("");
      setFileUpload(file);
      setTitleFile(file.name);
    }
  };

  useEffect(() => {
    if (fileUpload) setFileToSend(fileUpload);
  }, [fileUpload, setFileToSend]);

  useEffect(() => {
    setFileUpload(fileToSend);
    setTitleFile(fileToSend?.name || null);
  }, [fileToSend]);

  useEffect(() => {
    if (errors.length > 0)
      setErrorFileUpload("Le fichier ne correspond pas aux spécifications.");
    else setErrorFileUpload("");
  }, [errors]);

  return (
    <>
      <div className={"flex flex-col gap-2"}>
        <StyledInput
          label={"Importer"}
          type={"file"}
          fileName={titleFile}
          fileAccept={acceptFileType}
          fileSize={sizeFile.title}
          error={errorFileUpload}
          onChange={handleAddFile}
        />
        <div className={"text-xs text-red-500"}>
          {errors.map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export default SlideOverImportMissionImportFile;
