import Button from "../button";
import { Delete, Dots, Modify } from "../../assets/icons";
import { useEffect, useRef, useState } from "react";
import UseContext from "../../hooks/useContext";
import useModal from "../modal/useModal";

const TeamItem = ({ team }) => {
  const { toggle } = useModal();
  const { userData, setModalData } = UseContext();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef();

  const tRole = {
    200: "Observateur",
    205: "Manageur",
    210: "Direction",
    215: "Directeur",
    225: "Administrateur",
  };

  useEffect(() => {
    let handler = (e) => {
      if (!dropDownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleModify = (userId) => {
    setModalData({
      idUser: userId,
    });
    toggle("modify_team");
  };

  const handleDeleted = (userId) => {
    setModalData({
      idUser: userId,
    });
    toggle("delete_team");
  };

  return (
    <div
      className={"grid grid-cols-5 text-sm p-4 border-white border-b-gray-100"}
    >
      <div className={"col-span-3"}>
        <div className={"flex gap-6"}>
          <div
            className={
              "bg-gray-100 text-gray-600 rounded-full w-10 h-10 flex justify-center items-center text-base"
            }
          >
            {team.firstname.charAt(0)}
            {team.lastname.charAt(0)}
          </div>
          <div className={"flex flex-col"}>
            <div className={"font-semibold flex gap-1 items-center"}>
              <div>{team.firstname}</div>
              <div>{team.lastname}</div>
              <div>{team._id === userData?._id && "(Vous)"}</div>
            </div>
            <div className={"text-gray-500"}>{team.email}</div>
          </div>
        </div>
      </div>
      <div className={"flex items-center"}>
        {team.role === 225 ? (
          <div
            className={
              "rounded-full p-0.5 px-2 text-yellow-800 bg-yellow-100 w-fit"
            }
          >
            {tRole[team.role]}
          </div>
        ) : (
          <div
            className={
              "rounded-full p-0.5 px-2 text-gray-800 bg-gray-100 w-fit"
            }
          >
            {tRole[team.role]}
          </div>
        )}
      </div>
      <div className={"flex justify-end items-center"}>
        <div
          className={"w-fit relative"}
          ref={dropDownRef}
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <Button color={"ICON"} size={"ICON"}>
            <Dots wh={20} color={"#374151"} />
          </Button>
          {dropdownOpen && (
            <div
              className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 bottom-9 right-0 z-50 bg-gray-50`}
            >
              <div
                className="flex flex-row gap-2 items-center h-9 p-2 ft-sm cursor-pointer"
                onClick={() => {
                  handleModify(team._id);
                }}
              >
                <Modify wh={14} color={"#111827"} />
                Modifier
              </div>
              {team.role !== 225 && (
                <div
                  className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
                  onClick={() => {
                    handleDeleted(team._id);
                  }}
                >
                  <Delete wh={14} color={"#DC2626"} />
                  Supprimer
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeamItem;
