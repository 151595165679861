import React from "react";
import ReactDOM from "react-dom";
import DataModal from "../dataModal";
import ModalStructure from "../components/structure";
import UseContext from "../../../hooks/useContext";

const Index = () => {
  const { modalShow } = UseContext();

  if (!modalShow) return null;

  return ReactDOM.createPortal(
    <ModalStructure isShowing={modalShow}>
      <DataModal />
    </ModalStructure>,
    document.getElementById("root-portal"),
  );
};

export default Index;
