import React, { useEffect, useState } from "react";
import Badges from "../../../../badges";
import ApiDatabase from "../../../../../server";
import Banner from "../../../../banner/banner";

const SlideOverImportMissionFinal = ({
  fileData,
  companiesSelected,
  companiesNameRemote,
  setMissionsFinal,
  setIsMissionToImport,
}) => {
  const [missionGrouped, setMissionGrouped] = useState({});
  const [missionsRemote, setMissionsRemote] = useState([]);
  const [missionGroupedDisplay, setMissionGroupedDisplay] = useState([]);
  const [isWaitingFile, setIsWaitingFile] = useState(false);
  const [isWaitingMission, setIsWaitingMission] = useState(false);
  const [isWaitingMissionDisplay, setIsWaitingMissionDisplay] = useState(false);
  const [interimAgencies, setInterimAgencies] = useState([]);

  useEffect(() => {
    ApiDatabase.getAllInterimAgencies(
      { type: "peopulse" },
      (data) => {
        setInterimAgencies(data.interimAgencies || []);
      },
      (err) => {
        console.log("err", err);
      },
    );
  }, []);

  useEffect(() => {
    if (
      fileData &&
      companiesSelected &&
      companiesNameRemote &&
      interimAgencies
    ) {
      setIsWaitingFile(true);
      setIsWaitingMissionDisplay(true);
      const groupedData = {};

      for (let i = 0; i < fileData.length; i++) {
        const row = fileData[i];

        if (row.length === 0 || row.length === 1) continue;
        const nameCompany = row[13];
        if (
          nameCompany &&
          (companiesSelected
            .filter((c) => c.checked)
            .map((c) => c.name)
            .includes(nameCompany) ||
            companiesNameRemote.map((c) => c.name).includes(nameCompany))
        ) {
          if (!groupedData[nameCompany]) {
            groupedData[nameCompany] = [];
          }

          groupedData[nameCompany].push({
            idUser: row[1], // 2: idUser
            firstname: row[2], // 3: firstname
            lastname: row[3], // 4: lastname
            phone: row[29], // 30: phone
            dateStart: row[6], // 7: dateStart
            dateEnd: row[7], // 8: dateEnd
            idMission: row[12], // 13: idMission
            nameCompany: row[13], // 14: nameCompany
            interimAgency: row[17], // 18: interimAgency
            idCompany: row[27], // 28: idCompany
            outSection: row[4], // 5: outSection
            justification: row[15], // 16: justification
          });
        }
      }

      setIsWaitingFile(false);
      setMissionGrouped(groupedData);
      setMissionsFinal(groupedData);
    }
  }, [
    fileData,
    companiesSelected,
    setMissionsFinal,
    companiesNameRemote,
    interimAgencies,
  ]);

  useEffect(() => {
    if (fileData && companiesSelected && companiesNameRemote) {
      setIsWaitingMission(true);
      setIsWaitingMissionDisplay(true);
      ApiDatabase.getAllMissions(
        {},
        (data) => {
          setMissionsRemote(data.missions || []);
          setIsWaitingMission(false);
        },
        (err) => {
          console.log("err", err);
          setIsWaitingMission(false);
        },
      );
    }
  }, [companiesNameRemote, companiesSelected, fileData]);

  useEffect(() => {
    if (
      !isWaitingMission &&
      !isWaitingFile &&
      Object.keys(missionGrouped).length > 0
    ) {
      const missionsChanges = {};

      Object.keys(missionGrouped).forEach((company) => {
        for (let i = 0; i < missionsRemote.length; i++) {
          if (missionsRemote[i].companyName === company) {
            for (let j = 0; j < missionGrouped[company].length; j++) {
              if (
                missionGrouped[company][j].idMission ===
                missionsRemote[i].idMission
              ) {
                if (!missionsChanges[missionGrouped[company][j].idMission]) {
                  missionsChanges[missionGrouped[company][j].idMission] = {};
                }

                const formatDate = (dateStr) => {
                  const [year, month, day] = dateStr.slice(0, 10).split("-");
                  return `${day}/${month}/${year}`;
                };

                const dateStart = formatDate(missionsRemote[i].dates.start);
                const dateEnd = formatDate(missionsRemote[i].dates.end);

                if (
                  missionGrouped[company][j].dateStart !== dateStart ||
                  missionGrouped[company][j].dateEnd !== dateEnd
                ) {
                  missionsChanges[missionGrouped[company][j].idMission][
                    "dateStart"
                  ] = dateStart;
                  missionsChanges[missionGrouped[company][j].idMission][
                    "dateEnd"
                  ] = dateEnd;
                }

                if (
                  missionGrouped[company][j].firstname !==
                    missionsRemote[i].user.firstname ||
                  missionGrouped[company][j].lastname !==
                    missionsRemote[i].user.lastname
                ) {
                  missionsChanges[missionGrouped[company][j].idMission][
                    "firstname"
                  ] = missionsRemote[i].user.firstname;
                  missionsChanges[missionGrouped[company][j].idMission][
                    "lastname"
                  ] = missionsRemote[i].user.lastname;
                }

                if (
                  missionGrouped[company][j].nameCompany !==
                  missionsRemote[i].companyName
                ) {
                  missionsChanges[missionGrouped[company][j].idMission][
                    "nameCompany"
                  ] = missionsRemote[i].companyName;
                }
              }
            }
          }
        }
      });

      const newMissionGrouped = {};
      Object.keys(missionGrouped).map((company) => {
        missionGrouped[company].map((mission) => {
          if (missionsChanges[mission.idMission]) {
            if (Object.keys(missionsChanges[mission.idMission]).length > 0) {
              if (!newMissionGrouped[company]) {
                newMissionGrouped[company] = [];
              }
              newMissionGrouped[company].push({
                ...mission,
                isCreation: false,
              });
            }
          } else {
            if (!newMissionGrouped[company]) {
              newMissionGrouped[company] = [];
            }
            newMissionGrouped[company].push({ ...mission, isCreation: true });
          }
          return null;
        });
        return null;
      });

      setMissionGroupedDisplay(newMissionGrouped);
      setIsWaitingMissionDisplay(false);
    }
  }, [missionGrouped, isWaitingMission, isWaitingFile, missionsRemote]);

  useEffect(() => {
    if (Object.keys(missionGroupedDisplay).length > 0) {
      setIsMissionToImport(true);
    } else {
      setIsMissionToImport(false);
    }
  }, [missionGroupedDisplay, setIsMissionToImport]);

  return (
    <>
      {isWaitingMissionDisplay ? (
        <div className={"flex flex-col gap-6 animate-pulse"}>
          {[1, 2].map((i, index) => (
            <div key={index} className={"flex flex-col gap-3"}>
              <div
                className={"flex justify-between items-center gap-2 bg-white"}
              >
                <div className={"h-6 bg-gray-200 rounded w-32"}></div>
                <div className={"h-6 bg-gray-200 rounded w-6"}></div>
              </div>
              <div className={"flex flex-col gap-3"}>
                {[1, 2, 3].map((i, index) => (
                  <div
                    key={index}
                    className={"p-4 rounded border flex flex-col"}
                  >
                    <div className={"flex gap-1 justify-between"}>
                      <div className={""}>
                        <div className={"flex gap-1 font-black items-center"}>
                          <div className={"h-4 bg-gray-200 rounded w-28"}></div>
                          <div className={"h-4 bg-gray-200 rounded w-28"}></div>
                          <div>·</div>
                          <div className={"h-4 bg-gray-200 rounded w-12"}></div>
                        </div>
                        <div className={"flex gap-1 mt-1 items-center"}>
                          <div className={"h-4 bg-gray-200 rounded w-24"}></div>
                          <div>></div>
                          <div className={"h-4 bg-gray-200 rounded w-24"}></div>
                        </div>
                      </div>
                    </div>
                    <div className={"h-0.5 w-full bg-gray-100 my-1.5"}></div>
                    <div className={"text-gray-500 flex gap-1 items-center"}>
                      <div className={"h-4 bg-gray-200 rounded w-24"}></div>
                      <div>-</div>
                      <div className={"h-4 bg-gray-200 rounded w-12"}></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={"flex flex-col gap-6"}>
          {Object.keys(missionGroupedDisplay).length === 0 && (
            <Banner type={"alert"}>
              Il n'y a aucune mission à ajouter ou à mettre à jour
            </Banner>
          )}
          {Object.keys(missionGroupedDisplay).map((company, index) => (
            <div key={index} className={"flex flex-col gap-3"}>
              <div
                className={"flex justify-between items-center gap-2 bg-white"}
              >
                <div>{company}</div>
                <div className={"font-black"}>
                  <Badges color={"SECONDARY"} size={"SM"}>
                    {missionGroupedDisplay[company].length}
                  </Badges>
                </div>
              </div>
              <div className={"flex flex-col gap-3"}>
                {missionGroupedDisplay[company].map((mission, index) => (
                  <div
                    key={index}
                    className={"p-4 rounded border flex flex-col"}
                  >
                    <div className={"flex gap-1 justify-between"}>
                      <div className={""}>
                        <div className={"flex gap-1 font-black"}>
                          <div>{mission.firstname}</div>
                          <div>{mission.lastname}</div>
                          <div>·</div>
                          <div>{mission.idUser}</div>
                        </div>
                        <div className={"text-gray-700"}>
                          {mission.dateStart} > {mission.dateEnd}
                        </div>
                      </div>
                      {!mission.isCreation && (
                        <div className={""}>
                          <Badges color={"SECONDARY"}>Mis à jour</Badges>
                        </div>
                      )}
                    </div>
                    <div className={"h-0.5 w-full bg-gray-100 my-1.5"}></div>
                    <div className={"text-gray-500"}>
                      {interimAgencies.find(
                        (agency) => agency.reference === mission.interimAgency,
                      )?.name || mission.interimAgency}{" "}
                      - {mission.idMission}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SlideOverImportMissionFinal;
