import UseContext from "../../../hooks/useContext";
import ModalLogout from "./data/modalLogout/ModalLogout";
import ModalAddTeam from "./data/modalTeam/ModalAddTeam";
import ModalEditTempWorkerPhone from "./data/modalEditTempWorkerPhone/ModalEditTempWorkerPhone";
import ModalModifyTeam from "./data/modalTeam/ModalModifyTeam";
import ModalDeleteTeam from "./data/modalTeam/ModalDeleteTeam";
import ModalEditTempWorkerToken from "./data/modalEditTempWorkerToken/ModalEditTempWorkerToken";
import ModalLogTimeSheet from "./data/modalLogTimeSheet/ModalLogTimeSheet";

const dataModal = new Map();
dataModal.set("log_d_logout", <ModalLogout />);
dataModal.set("add_team", <ModalAddTeam />);
dataModal.set("modify_team", <ModalModifyTeam />);
dataModal.set("delete_team", <ModalDeleteTeam />);
dataModal.set("edit_temp_worker_phone", <ModalEditTempWorkerPhone />);
dataModal.set("edit_temp_worker_token", <ModalEditTempWorkerToken />);
dataModal.set("log_time_sheet", <ModalLogTimeSheet />);

const Datamodal = () => {
  const { modalType } = UseContext();
  return <>{dataModal.get(modalType)}</>;
};

export default Datamodal;
