import PageContainer from "../components/pageContainer/PageContainer";
import NavBarHourlyTracking from "../components/navbars/NavBarHourlyTracking";
import TableHourTrackingMission from "../components/tables/TableHourTrackingMission";
import { useCallback, useEffect, useRef, useState } from "react";
import EmptyState from "../components/EmptyState/EmptyState";
import ApiDatabase from "../server";
import UseContext from "../hooks/useContext";
import FooterHourlyTracking from "../components/footers/FooterHourlyTracking";
import useTitle from "../hooks/TitleManager";
import { useNavigate } from "react-router-dom";
import { forceDateToUtc0 } from "../utils/Date";

const HourlyTracking = () => {
  useTitle({ title: "Suivi horaire" });
  const {
    slideOverDataReturn,
    setSlideOverDataReturn,
    missions,
    setMissions,
    companySelected,
    setCompanySelected,
    companyList,
    setCompanyList,
    dateSelect,
    setDateSelect,
    dateList,
    setDateList,
  } = UseContext();
  const navigate = useNavigate();
  const [missionsToDisplay, setMissionsToDisplay] = useState([]);
  const [interimAgencyList, setInterimAgencyList] = useState([]);
  const [interimAgencySelected, setInterimAgencySelected] = useState([]);
  const [interimSectorList, setInterimSectorList] = useState([]);
  const [interimSectorSelected, setInterimSectorSelected] = useState([]);
  const [isWaitingMissions, setIsWaitingMissions] = useState(false);
  const [refreshMissions, setRefreshMissions] = useState(false);
  const [search, setSearch] = useState("");
  const [missionStatusSelected, setMissionStatusSelected] = useState([]);
  const [dateListGlobal, setDateListGlobal] = useState([]);
  const [missionStatusList, setMissionStatusList] = useState([
    { type: "inProgress", name: "En cours", checked: false },
    { type: "flexibility", name: "Souplesse", checked: false },
    { type: "unsigned", name: "Non émargé", checked: false },
    { type: "shiftCompleted", name: "Shift terminé", checked: false },
    { type: "shiftMissing", name: "Shift manquant", checked: false },
    { type: "missingPhoneNumber", name: "Numéro manquant", checked: false },
  ]);

  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 25;
  const [countTotal, setCountTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [initialParamsLoaded, setInitialParamsLoaded] = useState(false);
  const [isInterimAgencyListLoaded, setIsInterimAgencyListLoaded] =
    useState(false);
  const [isInterimSectorListLoaded, setIsInterimSectorListLoaded] =
    useState(false);
  const [isCompanyListLoaded, setIsCompanyListLoaded] = useState(false);

  let timerGetMissionSuggestions = useRef(null);

  useEffect(() => {
    if (missions.length > 0 && dateSelect) {
      const date = dateSelect.toISOString().split("T")[0];
      const dateNow = new Date().setHours(0, 0, 0, 0);

      let filteredByStatus;
      if (missionStatusSelected.length > 0) {
        filteredByStatus = missions.filter((mission) => {
          const dateMission = forceDateToUtc0(mission.date).setHours(
            0,
            0,
            0,
            0,
          );
          const isDateBeforeDateNow = dateMission < dateNow;
          const isDateAfterDateNow = dateMission > dateNow;

          return missionStatusSelected.some((status) => {
            switch (status.type) {
              case "flexibility":
                return mission.isFlexibilityDate;
              case "inProgress":
                return (
                  (mission.markup.start &&
                    mission.markup.end &&
                    !isDateBeforeDateNow &&
                    !isDateAfterDateNow) ||
                  (mission.markup.start &&
                    !mission.markup.end &&
                    !isDateBeforeDateNow &&
                    !isDateAfterDateNow)
                );
              case "unsigned":
                return (
                  !mission.markup.start &&
                  !mission.markup.end &&
                  !isDateAfterDateNow
                );
              case "shiftCompleted":
                return (
                  mission.markup.start &&
                  mission.markup.end &&
                  !isDateAfterDateNow &&
                  isDateBeforeDateNow
                );
              case "shiftMissing":
                return (
                  mission.markup.start &&
                  !mission.markup.end &&
                  isDateBeforeDateNow &&
                  !isDateAfterDateNow
                );
              case "missingPhoneNumber":
                return !mission.user.phone;
              default:
                return false;
            }
          });
        });
      } else {
        filteredByStatus = missions;
      }

      let dateListEdit = new Set();
      filteredByStatus.forEach((mission) => {
        dateListEdit.add(new Date(mission.date).toISOString().split("T")[0]);
      });

      dateListEdit = Array.from(dateListEdit)
        .map((dateStr) => forceDateToUtc0(dateStr))
        .sort((a, b) => a - b);

      setDateList(dateListEdit);

      const filteredByDate = filteredByStatus.filter((mission) => {
        const dateMission = new Date(mission.date).toISOString().split("T")[0];
        return dateMission === date;
      });

      const missionsByUserAndDate = {};

      filteredByDate.forEach((mission) => {
        const key = `${mission.user.idTempWorker}-${new Date(mission.date).toISOString().split("T")[0]}`;
        if (!missionsByUserAndDate[key]) {
          missionsByUserAndDate[key] = [];
        }
        missionsByUserAndDate[key].push(mission);
      });

      const finalMissions = [];

      Object.keys(missionsByUserAndDate).forEach((key) => {
        const missions = missionsByUserAndDate[key];
        const hasNonFlexible = missions.some(
          (mission) => mission.isFlexibilityDate === false,
        );

        if (hasNonFlexible) {
          missions.forEach((mission) => {
            if (mission.isFlexibilityDate === false) {
              finalMissions.push(mission);
            }
          });
        } else {
          finalMissions.push(...missions);
        }
      });

      setCountTotal(finalMissions.length);
      setTotalPages(Math.ceil(finalMissions.length / countPerPage));
      const start = (currentPage - 1) * countPerPage;
      const end = start + countPerPage;

      setMissionsToDisplay(finalMissions.slice(start, end));
    } else {
      setMissionsToDisplay([]);
    }
  }, [
    missions,
    dateSelect,
    currentPage,
    countPerPage,
    missionStatusSelected,
    setMissionsToDisplay,
    setDateList,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    dateSelect,
    search,
    companySelected,
    interimAgencySelected,
    interimSectorSelected,
    missionStatusList,
  ]);

  useEffect(() => {
    if (
      isInterimAgencyListLoaded &&
      isInterimSectorListLoaded &&
      isCompanyListLoaded &&
      missionStatusList.length > 0 &&
      !initialParamsLoaded
    ) {
      const params = new URLSearchParams(window.location.search);

      const date = params.get("dateSelect");
      if (date && new Date(date).toISOString() !== dateSelect?.toISOString()) {
        setDateSelect(new Date(date));
      }

      const searchParam = params.get("search");
      if (searchParam && searchParam !== search) {
        setSearch(searchParam);
      }

      const idCompany = params.get("companySelected");
      if (idCompany) {
        const selectedCompany = companyList.find(
          (company) => company.idCompany === idCompany,
        );
        if (
          selectedCompany &&
          selectedCompany.idCompany !== companySelected?.idCompany
        ) {
          setCompanySelected(selectedCompany);
        }
      }

      const interimAgencies = params.get("interimAgencySelected");
      if (interimAgencies) {
        const selectedAgencies = interimAgencies
          .split(",")
          .map((name) =>
            interimAgencyList.find((agency) => agency.name === name),
          )
          .filter((agency) => agency);
        const updatedAgencyList = interimAgencyList.map((interimAgency) => ({
          ...interimAgency,
          checked: interimAgencies.split(",").includes(interimAgency.name),
        }));

        if (
          JSON.stringify(updatedAgencyList) !==
            JSON.stringify(interimAgencyList) &&
          JSON.stringify(selectedAgencies) !==
            JSON.stringify(interimAgencySelected)
        ) {
          setInterimAgencyList(updatedAgencyList);
          setInterimAgencySelected(selectedAgencies);
        }
      }

      const interimSectors = params.get("interimSectorSelected");
      if (interimSectors) {
        const selectedSectors = interimSectors
          .split(",")
          .map((name) =>
            interimSectorList.find((sector) => sector.name === name),
          )
          .filter((sector) => sector);
        const updatedInterimSectorList = interimSectorList.map(
          (interimSector) => ({
            ...interimSector,
            checked: interimSectors.split(",").includes(interimSector.name),
          }),
        );

        if (
          JSON.stringify(updatedInterimSectorList) !==
            JSON.stringify(interimSectorList) &&
          JSON.stringify(selectedSectors) !==
            JSON.stringify(interimSectorSelected)
        ) {
          setInterimSectorList(updatedInterimSectorList);
          setInterimSectorSelected(selectedSectors);
        }
      }

      const missionStatuses = params.get("missionStatusList");
      if (missionStatuses) {
        const selectedMissionStatus = missionStatuses
          .split(",")
          .map((name) =>
            missionStatusList.find(
              (missionStatus) => missionStatus.name === name,
            ),
          )
          .filter((missionStatus) => missionStatus);
        const updatedMissionStatusList = missionStatusList.map(
          (missionStatus) => ({
            ...missionStatus,
            checked: missionStatuses.split(",").includes(missionStatus.name),
          }),
        );

        if (
          JSON.stringify(updatedMissionStatusList) !==
            JSON.stringify(missionStatusList) &&
          JSON.stringify(selectedMissionStatus) !==
            JSON.stringify(missionStatusSelected)
        ) {
          setMissionStatusList(updatedMissionStatusList);
          setMissionStatusSelected(selectedMissionStatus);
        }
      }

      setInitialParamsLoaded(true);
    }
  }, [
    isInterimAgencyListLoaded,
    isInterimSectorListLoaded,
    isCompanyListLoaded,
    initialParamsLoaded,
    interimAgencyList,
    interimSectorList,
    missionStatusList,
    companyList,
    dateSelect,
    search,
    companySelected,
    interimAgencySelected,
    interimSectorSelected,
    missionStatusSelected,
    setCompanySelected,
    setDateSelect,
  ]);

  useEffect(() => {
    ApiDatabase.getInterimAgencyMissions(
      {},
      (data) => {
        if (data.interimAgencies?.length > 0) {
          const selectedAgencyNames = interimAgencySelected?.map(
            (agency) => agency.name,
          );

          data.interimAgencies.forEach((agency) => {
            agency.checked = selectedAgencyNames.includes(agency.name);
          });

          setInterimAgencyList(data.interimAgencies);
        } else {
          setInterimAgencyList([]);
        }
        setIsInterimAgencyListLoaded(true);
      },
      (err) => {
        console.log(err);
        setIsInterimAgencyListLoaded(true);
      },
    );
  }, [
    refreshMissions,
    setInterimAgencySelected,
    setInterimAgencyList,
    interimAgencySelected,
  ]);

  useEffect(() => {
    ApiDatabase.getInterimSectorMissions(
      {},
      (data) => {
        if (data.interimSectors?.length > 0) {
          const selectedSectorNames = interimSectorSelected?.map(
            (sector) => sector.name,
          );

          data.interimSectors.forEach((sector) => {
            sector.checked = selectedSectorNames.includes(sector.name);
          });

          setInterimSectorList(data.interimSectors);
        } else {
          setInterimSectorList([]);
        }
        setIsInterimSectorListLoaded(true);
      },
      (err) => {
        console.log(err);
        setIsInterimSectorListLoaded(true);
      },
    );
  }, [
    refreshMissions,
    setInterimSectorSelected,
    setInterimSectorList,
    interimSectorSelected,
  ]);

  useEffect(() => {
    ApiDatabase.getCompaniesMissions(
      {},
      (data) => {
        if (data.companies?.length > 0) {
          let selectedCompanyIndex = -1;

          if (companySelected) {
            selectedCompanyIndex = data.companies.findIndex(
              (company) => company._id === companySelected.idCompany,
            );
          }

          data.companies.forEach((company, index) => {
            company.checked =
              index ===
              (selectedCompanyIndex !== -1 ? selectedCompanyIndex : 0);
          });

          if (selectedCompanyIndex === -1) {
            const firstCompany = data.companies[0];
            setCompanySelected({
              idCompany: firstCompany._id,
              name: firstCompany.companyName,
              syncDate: firstCompany.lastSyncDate,
              badgerName: firstCompany.badgerName,
            });
          }

          setCompanyList(
            data.companies.map((company) => ({
              idCompany: company._id,
              name: company.companyName || company.name,
              syncDate: company.lastSyncDate,
              badgerName: company.badgerName,
              checked: company.checked,
            })),
          );
        } else {
          setCompanyList([]);
        }
        setIsCompanyListLoaded(true);
      },
      (err) => {
        console.log(err);
        setIsCompanyListLoaded(true);
      },
    );
  }, [setCompanySelected, setCompanyList, companySelected]);

  useEffect(() => {
    if (initialParamsLoaded) {
      const params = new URLSearchParams(window.location.search);

      if (dateSelect) {
        params.set("dateSelect", dateSelect.toISOString());
      } else {
        params.delete("dateSelect");
      }

      if (search) {
        params.set("search", search);
      } else {
        params.delete("search");
      }

      if (companySelected?.idCompany) {
        params.set("companySelected", companySelected.idCompany);
      } else {
        params.delete("companySelected");
      }

      if (interimAgencySelected?.length > 0) {
        const agencies = interimAgencySelected
          .map((interim) => interim.name)
          .join(",");
        params.set("interimAgencySelected", agencies);
      } else {
        params.delete("interimAgencySelected");
      }

      if (interimSectorSelected?.length > 0) {
        const sectors = interimSectorSelected
          .map((interim) => interim.name)
          .join(",");
        params.set("interimSectorSelected", sectors);
      } else {
        params.delete("interimSectorSelected");
      }

      const selectedMissions = missionStatusList
        ?.filter((missionStatus) => missionStatus.checked)
        .map((missionStatus) => missionStatus.name);

      if (selectedMissions?.length > 0) {
        params.set("missionStatusList", selectedMissions.join(","));
      } else {
        params.delete("missionStatusList");
      }

      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState(null, "", newUrl);
    }
  }, [
    dateSelect,
    search,
    companySelected,
    interimAgencySelected,
    interimSectorSelected,
    missionStatusList,
    initialParamsLoaded,
  ]);

  const manageGetMission = useCallback(async () => {
    if (!initialParamsLoaded) return;

    setIsWaitingMissions(true);

    try {
      const idCompany = companySelected?.idCompany || "";

      if (idCompany) {
        const getMissionsPromise = () =>
          new Promise((resolve, reject) => {
            console.log(
              "interimAgencySelected",
              interimAgencySelected,
              "interimSectorSelected",
              interimSectorSelected,
            );
            ApiDatabase.getMissions(
              {
                idCompany,
                interimAgencies:
                  interimAgencySelected.length > 0
                    ? JSON.stringify(
                        interimAgencySelected.map((agency) => agency.name),
                      )
                    : [],
                interimSectors:
                  interimSectorSelected.length > 0
                    ? JSON.stringify(
                        interimSectorSelected.map((sector) => sector.name),
                      )
                    : [],
                search,
              },
              (data) => resolve(data),
              (err) => reject(err),
            );
          });

        const missionData = await getMissionsPromise();

        if (idCompany === missionData.companyId) {
          setMissions(missionData.missions || []);
          setDateListGlobal(missionData.missionsDates || []);
          setDateList(missionData.dateList || []);
        }
        setIsWaitingMissions(false);
      } else {
        setMissions([]);
        setIsWaitingMissions(false);
      }
    } catch (err) {
      if (err.name !== "CanceledError") {
        console.log(err);
      }
      setIsWaitingMissions(false);
    }
  }, [
    initialParamsLoaded,
    companySelected?.idCompany,
    interimAgencySelected,
    interimSectorSelected,
    search,
    setIsWaitingMissions,
    setMissions,
    setDateList,
  ]);

  useEffect(() => {
    if (initialParamsLoaded) {
      manageGetMission();
    }
    // eslint-disable-next-line
  }, [
    initialParamsLoaded,
    companySelected,
    interimAgencySelected,
    interimSectorSelected,
  ]);

  useEffect(() => {
    if (initialParamsLoaded) {
      timerGetMissionSuggestions.current = setTimeout(async () => {
        await manageGetMission();
      }, 400);

      return () => clearTimeout(timerGetMissionSuggestions.current);
    }
    // eslint-disable-next-line
  }, [initialParamsLoaded, search]);

  useEffect(() => {
    if (slideOverDataReturn.missions) {
      setRefreshMissions(!refreshMissions);
      setSlideOverDataReturn({});
    }
  }, [slideOverDataReturn, setSlideOverDataReturn, refreshMissions]);

  useEffect(() => {
    ApiDatabase.getInterimAgencyMissions(
      {},
      (data) => {
        if (data.interimAgencies?.length > 0) {
          const selectedAgencyNames = interimAgencySelected?.map(
            (agency) => agency.name,
          );

          data.interimAgencies.forEach((agency) => {
            agency.checked = selectedAgencyNames.includes(agency.name);
          });

          setInterimAgencyList(data.interimAgencies);
        } else {
          setInterimAgencyList([]);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }, [
    refreshMissions,
    setInterimAgencySelected,
    setInterimAgencyList,
    interimAgencySelected,
  ]);

  useEffect(() => {
    ApiDatabase.getInterimSectorMissions(
      {},
      (data) => {
        if (data.interimSectors?.length > 0) {
          const selectedSectorNames = interimSectorSelected?.map(
            (agency) => agency.name,
          );

          data.interimSectors.forEach((sector) => {
            sector.checked = selectedSectorNames.includes(sector.name);
          });

          setInterimSectorList(data.interimSectors);
        } else {
          setInterimSectorList([]);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }, [
    refreshMissions,
    setInterimSectorSelected,
    setInterimSectorList,
    interimSectorSelected,
  ]);

  const navigateToMission = (idMission, date) => {
    const params = new URLSearchParams(window.location.search);
    params.set("date", date);
    const newUrl = `/hourly-tracking/${idMission}?${params.toString()}`;
    navigate(newUrl);
  };

  return (
    <PageContainer
      type={"hourly_tracking"}
      dateSelect={dateSelect}
      setDateSelect={setDateSelect}
      dateList={dateListGlobal}
    >
      <div className={"flex flex-1 flex-col gap-4 h-full"}>
        <NavBarHourlyTracking
          dateSelect={dateSelect}
          setDateSelect={setDateSelect}
          dateList={dateList}
          dateListGlobal={dateListGlobal}
          companySelected={companySelected}
          companyList={companyList}
          setCompanySelected={setCompanySelected}
          setCompanyList={setCompanyList}
          interimAgencyList={interimAgencyList}
          setInterimAgencyList={setInterimAgencyList}
          interimAgencySelected={interimAgencySelected}
          setInterimAgencySelected={setInterimAgencySelected}
          interimSectorList={interimSectorList}
          setInterimSectorList={setInterimSectorList}
          interimSectorSelected={interimSectorSelected}
          setInterimSectorSelected={setInterimSectorSelected}
          search={search}
          setSearch={setSearch}
          missionStatusSelected={missionStatusSelected}
          setMissionStatusSelected={setMissionStatusSelected}
          missionStatusList={missionStatusList}
          setMissionStatusList={setMissionStatusList}
        />
        <div className={"h-0.5 w-full bg-gray-100"} />
        <div className={"flex flex-1 flex-col h-full overflow-auto"}>
          {isWaitingMissions ? (
            <div className={"flex flex-col gap-3 min-w-[830px] animate-pulse"}>
              {[1, 2, 3, 4, 5].map((index) => (
                <TableHourTrackingMission key={index} isWaiting={true} />
              ))}
            </div>
          ) : (
            <>
              {missionsToDisplay.length > 0 ? (
                <div className={"flex flex-col gap-3 min-w-[830px]"}>
                  {missionsToDisplay.map((mission, index) => (
                    <TableHourTrackingMission
                      key={index}
                      mission={mission}
                      navigateToMission={navigateToMission}
                    />
                  ))}
                </div>
              ) : (
                <EmptyState type={"hourly_tracking"} />
              )}
            </>
          )}
        </div>
        <FooterHourlyTracking
          syncDate={companySelected?.syncDate || null}
          badgerName={companySelected?.badgerName || null}
          currentPage={currentPage}
          countPerPage={countPerPage}
          countTotal={countTotal}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </PageContainer>
  );
};

export default HourlyTracking;
