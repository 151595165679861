import Modalheader from "../../../components/header";
import Button from "../../../../button";
import ModalFooter from "../../../components/footer";
import ModalBody from "../../../components/body";
import useModal from "../../../useModal";
import UseContext from "../../../../../hooks/useContext";
import { useEffect, useState } from "react";
import ApiDatabase from "../../../../../server";

const ModalDeleteTeam = () => {
  const { toggle } = useModal();
  const { modalData } = UseContext();
  const [isWaiting, setIsWaiting] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [isWaitingTeamInfo, setIsWaitingTeamInfo] = useState(false);

  useEffect(() => {
    if (modalData.idUser) {
      setIsWaitingTeamInfo(true);
      ApiDatabase.getTeamInfos(
        { idUser: modalData.idUser },
        (data) => {
          setIsWaitingTeamInfo(false);
          setFirstname(data.user?.firstname || "");
          setLastname(data.user?.lastname || "");
        },
        (err) => {
          setIsWaitingTeamInfo(false);
          console.log("err", err);
        },
      );
    }
  }, [modalData]);

  const handleSubmit = () => {
    if (modalData.idUser) {
      setIsWaiting(true);
      ApiDatabase.deleteTeamMember(
        { idUser: modalData.idUser },
        (data) => {
          setIsWaiting(false);
          toggle();
        },
        (err) => {
          setIsWaiting(false);
          console.log("err", err);
        },
      );
    }
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Modifier un membre
      </Modalheader>
      <ModalBody>
        {isWaitingTeamInfo ? (
          <div className={"animate-pulse"}>
            <div className={"h-2 bg-gray-200 rounded w-full"}></div>
          </div>
        ) : (
          `Êtes-vous sûr de vouloir supprimer le compte de ${firstname} ${lastname} ?`
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={"SECONDARY"} size={"LG"} onClick={toggle}>
          Annuler
        </Button>
        {isWaiting ? (
          <Button color={"DISABLED"} size={"LG"}>
            Confirmation ...
          </Button>
        ) : (
          <Button color={"PRIMARY"} size={"LG"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalDeleteTeam;
