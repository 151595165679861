import React from "react";
import { CloseModal } from "../../../assets/icons";
import "../../../styles/main.scss";

const Modalheader = ({ children, hide, hideShow }) => {
  return (
    <div className="flex items-start justify-between gap-3 p-5 rounded-t">
      <h3 className="ft-lg tab:ft-2xl text-[#202124] font-semibold my-auto">
        {children}
      </h3>
      {hideShow ? (
        <button
          className="p-1 ml-auto bg-transparent border-0 text-black ft-2xl leading-none font-semibold outline-none focus:outline-none my-auto"
          onClick={hide}
        >
          <span className="bg-transparent text-black h-6 w-6 text-2xl">
            <CloseModal></CloseModal>
          </span>
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default Modalheader;
