import { createContext, useEffect, useRef, useState } from "react";
import ApiDatabase from "../server";
import { jwtDecode } from "jwt-decode";

const roadContext = createContext({});

const ContextProvider = ({ children }) => {
  const [userData, setUserData] = useState({});
  const [ipInfoUser, setIpInfoUser] = useState({});

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [modalData, setModalData] = useState({});
  const [modalDataReturn, setModalDataReturn] = useState({});

  const [slideOverShow, setSlideOverShow] = useState(false);
  const [slideOverType, setSlideOverType] = useState("");
  const [slideOverData, setSlideOverData] = useState({});
  const [slideOverDataReturn, setSlideOverDataReturn] = useState({});

  const [nbEmployees, setNbEmployees] = useState(0);
  const [nbCompanies, setNbCompanies] = useState(0);

  const [timeCut, setTimeCut] = useState(0);
  const [mission, setMission] = useState([]);

  const [missions, setMissions] = useState([]);

  const [userMission, setUserMission] = useState({});

  const [companySelected, setCompanySelected] = useState();
  const [companyList, setCompanyList] = useState([]);
  const [teams, setTeams] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [dateSelect, setDateSelect] = useState(new Date());
  const [dateList, setDateList] = useState([new Date()]);

  const socketRef = useRef(null);
  useEffect(() => {
    const manageSocket = async () => {
      if (!socketRef.current) {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);

        socketRef.current =
          await ApiDatabase.renewTokenIfNeededAndConnectSocket();
        const cache = await ApiDatabase.getCache();

        const handleTimeSheetChange = (change, eventName) => {
          const cacheKeyMission = `get_mission/info/${change.idMission}_{"id":"${change.idMission}"}`;
          const cachedDataMission = cache.get(cacheKeyMission);
          const cacheKeyMissions = `get_mission/list?idCompany=${change.companyId}_{"company":"${change.companyId}"}`;
          const cachedDataMissions = cache.get(cacheKeyMissions);
          const cacheKeyTimeSheet = `get_timeSheet/${change._id}_{"id":"${change._id}"}`;
          const cachedDataTimeSheet = cache.get(cacheKeyTimeSheet);

          if (["create", "update"].includes(eventName)) {
            if (cachedDataMission && cachedDataMission.mission) {
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              cachedDataMission.mission = cachedDataMission.mission.map((m) => {
                if (
                  m.idTimeSheet === change.idTimeSheet ||
                  m.date === change.date
                ) {
                  return {
                    ...m,
                    markups: periods.map(({ hours }) => ({
                      start: hours.start,
                      end: hours.end,
                    })),
                    pause: change.pause || "",
                    totalHours: change.totalHours || "",
                    idTimeSheet: change._id,
                  };
                }
                return m;
              });
              cache.set(cacheKeyMissions, cachedDataMission);
            }

            if (cachedDataMissions && cachedDataMissions.missions) {
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  if (
                    m.idMission === change.idMission &&
                    m.date === change.date
                  ) {
                    return {
                      ...m,
                      markup: {
                        start: periods[0]?.hours.start || "",
                        end: periods[periods.length - 1]?.hours.end || "",
                        pause: change.pause || "",
                      },
                      idTimeSheet: change._id,
                    };
                  }
                  return m;
                },
              );
              cache.set(cacheKeyMissions, cachedDataMissions);
            }

            if (cachedDataTimeSheet && cachedDataTimeSheet.timeSheet) {
              cachedDataTimeSheet.timeSheet = {
                ...cachedDataTimeSheet.timeSheet,
                ...change,
              };
              cache.set(cacheKeyTimeSheet, cachedDataTimeSheet);
            }

            setMission((currentMissions) => {
              const index = currentMissions.findIndex(
                (item) =>
                  item.idTimeSheet === change._id && item.date === change.date,
              );
              if (index === -1) return currentMissions;
              const updatedMissions = [...currentMissions];
              const periods = [...change.periods].sort(
                (a, b) => a.position - b.position,
              );
              updatedMissions[index] = {
                ...updatedMissions[index],
                markups: periods.map(({ hours }) => ({
                  start: hours.start,
                  end: hours.end,
                })),
                pause: change.pause || "",
                totalHours: change.totalHours || "",
                idTimeSheet: change._id,
              };
              return updatedMissions;
            });

            setMissions((currentMissions) =>
              currentMissions.map((m) => {
                if (
                  m.idMission === change.idMission &&
                  m.date === change.date
                ) {
                  const periods = [...change.periods].sort(
                    (a, b) => a.position - b.position,
                  );
                  return {
                    ...m,
                    markup: {
                      start: periods[0]?.hours.start || "",
                      end: periods[periods.length - 1]?.hours.end || "",
                      pause: change.pause || "",
                    },
                    idTimeSheet: change._id,
                  };
                }
                return m;
              }),
            );
          }
          if (eventName === "delete") {
            if (cachedDataMission && cachedDataMission.mission) {
              cachedDataMission.mission = cachedDataMission.mission.map((m) => {
                if (m.idTimeSheet === change._id) {
                  return {
                    ...m,
                    markups: [],
                    pause: "",
                    totalHours: "",
                    idTimeSheet: null,
                  };
                }
                return m;
              });
              cache.set(cacheKeyMission, cachedDataMission);
            }

            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  if (m.idTimeSheet === change._id) {
                    return {
                      ...m,
                      markup: { start: "", end: "", pause: "" },
                      idTimeSheet: null,
                    };
                  }
                  return m;
                },
              );
              cache.set(cacheKeyMissions, cachedDataMissions);
            }
            setMission((currentMissions) =>
              currentMissions.map((item) => {
                if (item.idTimeSheet === change._id) {
                  return {
                    ...item,
                    markups: [],
                    pause: "",
                    totalHours: "",
                    idTimeSheet: null,
                  };
                }
                return item;
              }),
            );

            setMissions((currentMissions) =>
              currentMissions.map((item) => {
                if (item.idTimeSheet === change._id) {
                  return {
                    ...item,
                    markup: { start: "", end: "", pause: "" },
                    idTimeSheet: null,
                  };
                }
                return item;
              }),
            );
          }
        };

        const handleMissionChange = (change, eventName) => {
          const cacheKeyMissions = `get_mission/list?idCompany=${change[0]?.companyId || change?.companyId}_{"company":"${change[0]?.companyId || change?.companyId}"}`;
          const cachedDataMissions = cache.get(cacheKeyMissions);

          if (eventName === "create") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              change.forEach((c) => {
                if (
                  !cachedDataMissions.missions.find(
                    (m) => m._id === c._id && m.date === c.date,
                  )
                ) {
                  cachedDataMissions.missions.push(c);
                }
              });
              cache.set(cacheKeyMissions, cachedDataMissions);
            }
            setMissions((currentMissions) => {
              const newMissions = [...currentMissions];
              change.forEach((c) => {
                if (
                  !newMissions.find((m) => m._id === c._id && m.date === c.date)
                ) {
                  newMissions.push(c);
                }
              });
              return newMissions;
            });
          }
          if (eventName === "update") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.map(
                (m) => {
                  const matchingChange = change.find(
                    (c) => m._id === c._id && m.date === c.date,
                  );
                  return matchingChange ? { ...m, ...matchingChange } : m;
                },
              );
              cache.set(cacheKeyMissions, cachedDataMissions);
            }

            setMissions((currentMissions) =>
              currentMissions.map((mission) => {
                const matchingChange = change.find(
                  (c) => mission._id === c._id && mission.date === c.date,
                );
                return matchingChange
                  ? { ...mission, ...matchingChange }
                  : mission;
              }),
            );
          }
          if (eventName === "delete") {
            if (cachedDataMissions && cachedDataMissions.missions) {
              cachedDataMissions.missions = cachedDataMissions.missions.filter(
                (m) => m._id !== change._id,
              );
              cache.set(cacheKeyMissions, cachedDataMissions);
            }
            setMissions((currentMissions) =>
              currentMissions.filter((m) => m._id !== change._id),
            );
          }
        };

        const handleUserMissionChange = (change, eventName) => {
          const cacheKeyUserMission = `get_mission/user/${change[0]?.idMission}_{"id":"${change[0]?.idMission}"}`;
          const cachedDataUserMission = cache.get(cacheKeyUserMission);

          if (eventName === "update") {
            if (cachedDataUserMission && cachedDataUserMission.user) {
              cachedDataUserMission.user.phone = change[0].user.phone;
              cache.set(cacheKeyUserMission, cachedDataUserMission);
            }

            setUserMission((currentUserMission) => ({
              ...currentUserMission,
              phone: change[0].user.phone,
            }));
          }
        };

        const handleCompanyChange = (change, eventName) => {
          const cacheKeyCompanies = `get_mission/companies_{}`;
          const cachedDataCompanies = cache.get(cacheKeyCompanies);
          const cacheKeyCompaniesEmployee = `get_company/companiesInfos/employees_{}`;
          const cachedDataCompaniesEmployee = cache.get(
            cacheKeyCompaniesEmployee,
          );
          const cacheKeyNbInSettings = `get_bigCompany/getNbInSettings_{}`;
          const cachedDataNbInSettings = cache.get(cacheKeyNbInSettings);

          if (["create", "update"].includes(eventName)) {
            if (cachedDataCompanies && cachedDataCompanies.companies) {
              cachedDataCompanies.companies = change
                .filter(
                  (company) =>
                    company.nbMissions > 0 &&
                    company.employees.some(
                      (employee) => employee.idEmployee === decodedToken.userId,
                    ),
                )
                .sort((a, b) => a.name.localeCompare(b.name));
              cache.set(cacheKeyCompanies, cachedDataCompanies);
            }
            if (
              cachedDataCompaniesEmployee &&
              cachedDataCompaniesEmployee.companies
            ) {
              const updatedCompanies = change.map((changedCompany) => ({
                ...(cachedDataCompaniesEmployee.companies.find(
                  (company) => company._id === changedCompany._id,
                ) || {}),
                ...changedCompany,
                employees: changedCompany.employeesFind,
              }));

              cachedDataCompaniesEmployee.companies = updatedCompanies;

              cache.set(cacheKeyCompaniesEmployee, cachedDataCompaniesEmployee);
            }
            if (cachedDataNbInSettings && cachedDataNbInSettings.nbCompanies) {
              cachedDataNbInSettings.nbCompanies = change.length;
              cache.set(cacheKeyNbInSettings, cachedDataNbInSettings);
            }
            change[0].checked = true;

            const selectCompany = (prevData, change) => {
              let actualCompanySelected = prevData;

              const filteredCompanies = change
                .filter(
                  (company) =>
                    company.nbMissions > 0 &&
                    company.employees.some(
                      (employee) => employee.idEmployee === decodedToken.userId,
                    ),
                )
                .map((company) => ({
                  idCompany: company._id,
                  name: company.name,
                  syncDate: company.syncDate,
                  checked: false,
                }));

              filteredCompanies.sort((a, b) => a.name.localeCompare(b.name));

              const isPreviousCompanyInList = filteredCompanies.some(
                (company) => company.idCompany === prevData?.idCompany,
              );

              if (!isPreviousCompanyInList && filteredCompanies.length > 0) {
                actualCompanySelected = {
                  idCompany: filteredCompanies[0].idCompany,
                  name: filteredCompanies[0].name,
                  syncDate: filteredCompanies[0].syncDate,
                };
              }

              return { actualCompanySelected, filteredCompanies };
            };

            setCompanySelected((prevData) => {
              const { actualCompanySelected, filteredCompanies } =
                selectCompany(prevData, change);

              updateCompanyList(actualCompanySelected, filteredCompanies);

              return actualCompanySelected;
            });

            const updateCompanyList = (
              actualCompanySelected,
              filteredCompanies,
            ) => {
              setCompanyList(
                filteredCompanies.map((company) => ({
                  ...company,
                  checked:
                    actualCompanySelected?.idCompany &&
                    actualCompanySelected.idCompany.toString() ===
                      company.idCompany.toString(),
                })),
              );
            };

            setCompanies((currentCompanies) => {
              const updatedCompanies = change.map((changedCompany) => ({
                ...(currentCompanies.find(
                  (company) => company._id === changedCompany._id,
                ) || {}),
                ...changedCompany,
                employees: changedCompany.employeesFind,
              }));

              return updatedCompanies;
            });

            setNbCompanies(change.length);
          }
        };

        const handleBigCompanyChange = (change, eventName) => {
          const cacheKeyBigCompanyTimeCut = `get_bigCompany/getTimeCut_{}`;
          const cachedDataBigCompanyTimeCut = cache.get(
            cacheKeyBigCompanyTimeCut,
          );
          const cacheKeyUserCompany = `get_bigCompany/getTeams_{}`;
          const cachedDataUserCompany = cache.get(cacheKeyUserCompany);
          const cacheKeyNbInSettings = `get_bigCompany/getNbInSettings_{}`;
          const cachedDataNbInSettings = cache.get(cacheKeyNbInSettings);

          if (eventName === "update") {
            if (
              cachedDataBigCompanyTimeCut &&
              cachedDataBigCompanyTimeCut.timeCut
            ) {
              cachedDataBigCompanyTimeCut.timeCut = change.timeCut * 60;
              cache.set(cacheKeyBigCompanyTimeCut, cachedDataBigCompanyTimeCut);
            }
            if (cachedDataUserCompany && cachedDataUserCompany.teams) {
              cachedDataUserCompany.teams = change.teams;
              cache.set(cacheKeyUserCompany, cachedDataUserCompany);
            }
            if (cachedDataNbInSettings && cachedDataNbInSettings.nbCompanies) {
              cachedDataNbInSettings.nbCompanies = change.nbCompanies;
              cache.set(cacheKeyNbInSettings, cachedDataNbInSettings);
            }
            if (cachedDataNbInSettings && cachedDataNbInSettings.nbEmployees) {
              cachedDataNbInSettings.nbEmployees = change.employees.length;
              cache.set(cacheKeyNbInSettings, cachedDataNbInSettings);
            }
            setTeams(change.teams);
            setNbEmployees(change.employees.length);
            setTimeCut(change.timeCut);
            setNbCompanies(change.nbCompanies);
          }
        };

        const handleUserChange = (change, eventName) => {
          const cacheKeyUser = `post_user/teamInfo_{"idUser":"${change._id}"}`;
          const cachedDataUser = cache.get(cacheKeyUser);
          const cacheKeyUserCompany = `get_bigCompany/getTeams_{}`;
          const cachedDataUserCompany = cache.get(cacheKeyUserCompany);
          const cacheKeyUserData = `post_user/info_{}`;
          const cachedDataUserData = cache.get(cacheKeyUserData);

          if (eventName === "update") {
            if (cachedDataUser && cachedDataUser.user) {
              cachedDataUser.user = change;
              cache.set(cacheKeyUser, cachedDataUser);
            }
            if (cachedDataUserCompany && cachedDataUserCompany.teams) {
              const teamIndex = cachedDataUserCompany.teams.findIndex(
                (team) => team._id === change._id,
              );
              if (teamIndex !== -1) {
                cachedDataUserCompany.teams[teamIndex] = {
                  ...change,
                  role: cachedDataUserCompany.teams[teamIndex].role,
                };
              }
            }
            if (cachedDataUserData && cachedDataUserData.user) {
              if (cachedDataUserData.user._id === change._id) {
                cachedDataUserData.user = {
                  ...change,
                  role: cachedDataUserData.user.role,
                };
              }
            }
            setTeams((currentTeams) => {
              const index = currentTeams.findIndex(
                (team) => team._id === change._id,
              );
              if (index === -1) return currentTeams;
              const updatedTeams = [...currentTeams];
              updatedTeams[index] = {
                ...change,
                role: updatedTeams[index].role,
              };
              return updatedTeams;
            });
            setUserData((currentUserData) => {
              if (currentUserData._id === change._id) {
                return { ...change, role: currentUserData.role };
              }
              return currentUserData;
            });
          }
        };

        socketRef.current.on("timeSheetChange", (change) =>
          handleTimeSheetChange(change, "update"),
        );
        socketRef.current.on("timeSheetCreate", (change) =>
          handleTimeSheetChange(change, "create"),
        );
        socketRef.current.on("timeSheetDelete", (change) =>
          handleTimeSheetChange(change, "delete"),
        );
        socketRef.current.on("missionCreate", (change) => {
          handleMissionChange(change, "create");
        });
        socketRef.current.on("missionChange", (change) => {
          handleMissionChange(change, "update");
          handleUserMissionChange(change, "update");
        });
        socketRef.current.on("missionDelete", (change) =>
          handleMissionChange(change, "delete"),
        );
        socketRef.current.on("companiesUpdate", (change) =>
          handleCompanyChange(change, "update"),
        );
        socketRef.current.on("bigCompanyUpdate", (change) =>
          handleBigCompanyChange(change, "update"),
        );
        socketRef.current.on("userUpdate", (change) =>
          handleUserChange(change, "update"),
        );
      }
    };
    manageSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  return (
    <roadContext.Provider
      value={{
        userData,
        setUserData,
        ipInfoUser,
        setIpInfoUser,
        modalShow,
        setModalShow,
        modalType,
        setModalType,
        slideOverShow,
        setSlideOverShow,
        slideOverType,
        setSlideOverType,
        slideOverData,
        setSlideOverData,
        slideOverDataReturn,
        setSlideOverDataReturn,
        modalData,
        setModalData,
        modalDataReturn,
        setModalDataReturn,
        nbEmployees,
        setNbEmployees,
        nbCompanies,
        setNbCompanies,
        timeCut,
        setTimeCut,
        mission,
        setMission,
        missions,
        setMissions,
        userMission,
        setUserMission,
        companySelected,
        setCompanySelected,
        companyList,
        setCompanyList,
        teams,
        setTeams,
        companies,
        setCompanies,
        dateSelect,
        setDateSelect,
        dateList,
        setDateList,
      }}
    >
      {children}
    </roadContext.Provider>
  );
};

export { ContextProvider, roadContext };
