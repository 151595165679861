import React from "react";
import {
  EmptyStateHourlyTracking,
  EmptyStateMissionTimeSheetPeriod,
} from "../../assets/icons";

const EmptyState = ({ type, custom }) => {
  const logo = {
    hourly_tracking: (
      <div className={"mx-auto my-2"}>
        <EmptyStateHourlyTracking wh={256} />
      </div>
    ),
    mission_time_sheet_period: (
      <div className={"mx-auto my-2"}>
        <EmptyStateMissionTimeSheetPeriod wh={120} />
      </div>
    ),
    activity: (
      <div className={"mx-auto my-2"}>
        <EmptyStateHourlyTracking wh={256} />
      </div>
    ),
  };

  const title = {
    hourly_tracking: "Pas de missions disponible",
    mission_time_sheet_period: "Aucun pointage disponible",
    activity: "Aucune activitée disponible",
  };

  const description = {
    hourly_tracking:
      "Importez des missions pour les consulter sur votre espace Workflow.",
    mission_time_sheet_period: "Il n y a pas de pointages pour ce jour.",
    activity: "Vous n'avez pas encore d'activités.",
  };

  const button = {
    hourly_tracking: "",
  };

  return (
    <>
      <div className={"max-w-[535px] m-auto text-center p-3"}>
        <div className={"flex"}>{logo[type]}</div>
        <div className={"font-medium ft-xl text-[#5C616D]"}>{title[type]}</div>
        <div className={"font-normal ft-b mt-2 text-[#757575]"}>
          {description[type]}
        </div>
        <div className={"flex flex-col"}>
          <div className={"mx-auto"}>{button[type]}</div>
          <div className={"mx-auto"}>{custom}</div>
        </div>
      </div>
    </>
  );
};
export default EmptyState;
