import PageContainer from "../components/pageContainer/PageContainer";
import SidebarSettings from "../components/sidebars/SidebarSettings";
import Button from "../components/button";
import { Plus } from "../assets/icons";
import { useEffect, useState } from "react";
import ApiDatabase from "../server";
import UseContext from "../hooks/useContext";
import useModal from "../components/modal/useModal";
import useTitle from "../hooks/TitleManager";
import TeamItem from "../components/teamItem/TeamItem";

const SettingsTeam = () => {
  useTitle({ title: "Paramètres - Equipe" });
  const { modalDataReturn, setModalDataReturn, teams, setTeams } = UseContext();
  const { toggle } = useModal();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    setIsWaiting(true);
    ApiDatabase.getTeams(
      {},
      (res) => {
        setTeams(res.teams);
        setIsWaiting(false);
      },
      (err) => {
        console.log("err", err);
        setIsWaiting(false);
      },
    );
  }, [setTeams]);

  const handleAddTeam = () => {
    toggle("add_team");
  };

  useEffect(() => {
    if (modalDataReturn.employees) {
      setTeams(modalDataReturn.employees);
      setModalDataReturn({});
    }
  }, [modalDataReturn, setModalDataReturn, setTeams]);

  return (
    <PageContainer type={"settings_team"}>
      <div className={"flex gap-6 h-full"}>
        <SidebarSettings />
        <div
          className={
            "w-full h-full p-6 overflow-y-auto border rounded-lg flex flex-col gap-4"
          }
        >
          <div className={"flex justify-end"}>
            <Button color={"PRIMARY"} size={"SM"} onClick={handleAddTeam}>
              <div className={"flex gap-1 items-center"}>
                <Plus wh={20} color={"#FFFFFF"} />
                <div>Inviter un membre</div>
              </div>
            </Button>
          </div>
          <div className={"flex flex-col"}>
            <div className={"grid grid-cols-5 text-sm p-4"}>
              <div className={"col-span-3"}>MEMBRES</div>
              <div className={"text-gray-500"}>RÔLE</div>
            </div>
            {isWaiting
              ? [1, 2, 3, 4].map((index) => (
                  <div
                    key={index}
                    className={
                      "grid grid-cols-5 text-sm p-4 border-white border-b-gray-100 animate-pulse"
                    }
                  >
                    <div className={"col-span-3"}>
                      <div className={"flex gap-6"}>
                        <div className={"h-10 bg-gray-200 rounded w-10"}></div>
                        <div className={"flex flex-col gap-1"}>
                          <div className={"h-4 bg-gray-200 rounded w-32"}></div>
                          <div className={"h-4 bg-gray-200 rounded w-40"}></div>
                        </div>
                      </div>
                    </div>
                    <div className={"flex items-center"}>
                      <div className={"h-4 bg-gray-200 rounded w-40"}></div>
                    </div>
                    <div className={"flex justify-end items-center"}>
                      <div className={"h-8 bg-gray-200 rounded w-8"}></div>
                    </div>
                  </div>
                ))
              : teams.length > 0 &&
                teams.map((team, index) => (
                  <TeamItem team={team} key={index} />
                ))}
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

export default SettingsTeam;
