import SlideOverHeader from "../../../component/header";
import SlideOverFooter from "../../../component/footer";
import Button from "../../../../button";
import useSlideOver from "../../../useSlideOver";
import SlideOverBody from "../../../component/body";
import { useEffect, useRef, useState } from "react";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";
import {
  Building,
  PeopulseIcon,
  WarningCircle,
  WorkfullyLogoMini,
} from "../../../../../assets/icons";
import StyledInput from "../../../../styledInput/StyledInput";
import DatePicker from "react-datepicker";

const SlideOverExportMission = () => {
  const { toggleSlideOver } = useSlideOver();
  const { companySelected } = UseContext();
  const [isLoadingExportMission, setIsLoadingExportMission] = useState(false);
  const [errorExportMission, setErrorExportMission] = useState(false);
  const [errorCompany, setErrorCompany] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorTypeExport, setErrorTypeExport] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [companySelectedExport, setCompanySelectedExport] =
    useState(companySelected);
  const [dateSelect, setDateSelect] = useState([null, null]);
  const [isWaitingCompanies, setIsWaitingCompanies] = useState(true);
  const [typeExportSelected, setTypeExportSelected] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef();

  const typeExport = [
    {
      icon: <PeopulseIcon wh={19} />,
      label: "Peopulse",
      description: "",
      type: "peopulse",
    },
    {
      icon: <WorkfullyLogoMini wh={19} />,
      label: "Fichier Workfully",
      description: "Détails des pointages des intérimaire.",
      type: "workfully",
    },
  ];

  useEffect(() => {
    let handler = (e) => {
      if (!dropDownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const closeSlideOver = () => {
    toggleSlideOver();
  };

  const handleSubmit = () => {
    setIsLoadingExportMission(true);
    if (!companySelectedExport.idCompany) {
      setErrorCompany("Veuillez sélectionner une entreprise");
    } else {
      setErrorCompany("");
    }
    if (!dateSelect[0] || !dateSelect[1]) {
      setErrorDate("Veuillez sélectionner une période");
    } else {
      setErrorDate("");
    }
    if (!typeExportSelected) {
      setErrorTypeExport("Veuillez sélectionner un type d'export");
    } else {
      setErrorTypeExport("");
    }

    if (
      companySelectedExport.idCompany &&
      dateSelect[0] &&
      dateSelect[1] &&
      typeExportSelected
    ) {
      ApiDatabase.getExportMissions(
        {
          idCompany: companySelectedExport.idCompany,
          period: JSON.stringify(dateSelect),
          type: typeExportSelected,
        },
        (data) => {
          if (data.timeSheetsCSV) {
            setErrorExportMission(false);
            const blob = new Blob([data.timeSheetsCSV], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            //get date now AAAAMMJJHHMMSS
            const date = new Date();
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const hours = date.getHours().toString().padStart(2, "0");
            const minutes = date.getMinutes().toString().padStart(2, "0");
            const seconds = date.getSeconds().toString().padStart(2, "0");

            const dateNow = year + month + day + hours + minutes + seconds;

            a.download = `Heures_${dateNow}.csv`;
            a.click();
          } else {
            setErrorExportMission("Aucunes données à exporter");
          }
          setIsLoadingExportMission(false);
        },
        (err) => {
          setIsLoadingExportMission(false);
          console.warn(err);
        },
      );
    } else {
      setIsLoadingExportMission(false);
    }
  };

  useEffect(() => {
    const fetchCompaniesMissions = async () => {
      try {
        setIsWaitingCompanies(true);
        const getCompaniesMissionsPromise = () =>
          new Promise((resolve, reject) => {
            ApiDatabase.getCompaniesMissions(
              {},
              (data) => resolve(data),
              (err) => reject(err),
            );
          });

        const data = await getCompaniesMissionsPromise();

        if (data.companies?.length > 0) {
          setCompanyList(
            data.companies.map((company) => ({
              idCompany: company._id,
              name: company.companyName || company.name,
            })),
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsWaitingCompanies(false);
      }
    };

    fetchCompaniesMissions();
  }, [companySelected, companySelectedExport]);

  const updateCompaniesList = (updatedCompany) => {
    const value = updatedCompany.target.value;
    const companySelected = companyList.find(
      (company) => company.idCompany === value,
    );

    if (companySelected) {
      setCompanySelectedExport(companySelected);
    }
  };

  return (
    <>
      <SlideOverHeader hide={closeSlideOver} hideShow={true}>
        <div className={"text-lg"}>Exporter les pointages</div>
        <div className={"flex gap-1 text-sm"}>
          <div className={"text-gray-500"}>
            Exportez les pointages sur une période donnée pour l’enseigne
          </div>
          <div className={"font-black"}>{companySelectedExport.name}.</div>
        </div>
      </SlideOverHeader>
      <SlideOverBody>
        <div className={"flex flex-col gap-4 h-full"}>
          {!isWaitingCompanies ? (
            <>
              {companyList.length > 1 && (
                <div className={"text-sm text-gray-700"}>
                  <StyledInput
                    type={"select"}
                    label={"Établissement"}
                    imgFront={<Building wh={20} color={"#6B7280"} />}
                    value={companySelectedExport?.idCompany || ""}
                    list={companyList.map((company) => {
                      return {
                        value: company.idCompany,
                        label: company.name,
                        checked: company.checked,
                      };
                    })}
                    error={errorCompany}
                    onChange={(updatedCompany) =>
                      updateCompaniesList(updatedCompany)
                    }
                  />
                </div>
              )}
            </>
          ) : (
            <div className={"h-12 bg-gray-200 rounded"}></div>
          )}
          <div className={"flex flex-col gap-1 text-sm"}>
            <label className={"flex flex-col gap-1"}>
              <div className={"text-gray-500"}>Période</div>
              <DatePicker
                dateFormat="dd/MM/yyyy"
                onChange={(dates) => setDateSelect(dates)}
                startDate={dateSelect[0]}
                endDate={dateSelect[1]}
                locale="fr"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                popperPlacement="bottom"
                className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-sm"
                selectsRange={true}
                placeholderText="Sélectionnez"
                maxDate={new Date()}
              />
            </label>
            {errorDate && (
              <div className={"text-red-500 flex items-center gap-1 text-xs"}>
                <WarningCircle wh={16} color={"#EF4444"} />
                {errorDate}
              </div>
            )}
          </div>
          <div
            className={"relative"}
            ref={dropDownRef}
            onClick={() => {
              setDropdownOpen(!dropdownOpen);
            }}
          >
            <label className={"flex flex-col gap-1 text-sm"}>
              <div className={"text-gray-500"}>Type d'export</div>
              <div className={"border rounded-lg px-4 py-2"}>
                {typeExportSelected ? (
                  typeExport.find(
                    (typeExp) => typeExp.type === typeExportSelected,
                  ).label
                ) : (
                  <div className={"text-gray-500"}>Sélectionnez</div>
                )}
              </div>
              {errorTypeExport && (
                <div className={"text-red-500 flex items-center gap-1 text-xs"}>
                  <WarningCircle wh={16} color={"#EF4444"} />
                  {errorTypeExport}
                </div>
              )}
            </label>
            {dropdownOpen && (
              <div
                className={`absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-full top-16 right-0 z-50 bg-gray-50 cursor-pointer`}
              >
                {typeExport.map((typeExp, index) => (
                  <div
                    key={index}
                    className={
                      "flex gap-4 items-center px-4 py-2 border-b last:border-b-0 last:rounded-b-lg"
                    }
                    onClick={() => {
                      setTypeExportSelected(typeExp.type);
                      setDropdownOpen(false);
                    }}
                  >
                    <div
                      className={
                        "w-[28px] h-[28px] flex justify-center items-center border rounded"
                      }
                    >
                      {typeExp.icon}
                    </div>
                    <div>
                      <div className={"text-sm"}>{typeExp.label}</div>
                      <div className={"text-xs text-gray-500"}>
                        {typeExp.description}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {errorExportMission && (
            <div className={"flex gap-1 items-center text-sm text-red-500"}>
              <WarningCircle wh={16} color={"#EF4444"} />
              <div>{errorExportMission}</div>
            </div>
          )}
        </div>
      </SlideOverBody>
      <SlideOverFooter>
        <Button size={"SM"} color={"SECONDARY"} onClick={closeSlideOver}>
          Fermer
        </Button>
        {isLoadingExportMission ? (
          <Button size={"SM"} color={"DISABLED"}>
            Confirmation...
          </Button>
        ) : (
          <Button size={"SM"} color={"PRIMARY"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </SlideOverFooter>
    </>
  );
};

export default SlideOverExportMission;
