import SlideOverFooter from "../../../component/footer";
import Button from "../../../../button";
import SlideOverHeader from "../../../component/header";
import SlideOverBody from "../../../component/body";
import useSlideOver from "../../../useSlideOver";
import { useEffect, useState } from "react";
import SlideOverImportMissionImportFile from "./SlideOverImportMissionImportFile";
import Papa from "papaparse";
import missionFileImportAuthenticity from "../../../../../utils/MissionFileImportAuthenticity";
import SlideOverImportMissionAddCompany from "./SlideOverImportMissionAddCompany";
import SlideOverImportMissionFinal from "./SlideOverImportMissionFinal";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";

const SlideOverImportMission = () => {
  const { toggleSlideOver } = useSlideOver();
  const { setSlideOverDataReturn } = UseContext();
  const [page, setPage] = useState(1);
  const [fileToSend, setFileToSend] = useState(null);
  const [isLoadingFileToSend, setIsLoadingFileToSend] = useState(false);
  const [errorFileSend, setErrorFileSend] = useState([]);
  const [companiesToAdd, setCompaniesToAdd] = useState([]);
  const [companiesSelected, setCompaniesSelected] = useState([]);
  const [fileJSON, setFileJSON] = useState([]);
  const [companiesNameRemote, setCompaniesNameRemote] = useState([]);
  const [missionsFinal, setMissionsFinal] = useState([]);
  const [isLoadingImportMission, setIsLoadingImportMission] = useState(false);
  const [isMissionToImport, setIsMissionToImport] = useState(false);

  const closeSlideOver = () => {
    toggleSlideOver();
  };

  const handleSubmitFile = async () => {
    if (page === 1) {
      if (fileToSend) {
        setIsLoadingFileToSend(true);
        const fileJSON = await convertCsvToJson(fileToSend);
        const validationErrors = missionFileImportAuthenticity(fileJSON);
        if (validationErrors.length > 0) {
          setErrorFileSend(validationErrors);
          setIsLoadingFileToSend(false);
          setFileJSON([]);
        } else {
          setErrorFileSend([]);
          setIsLoadingFileToSend(false);
          setFileJSON(fileJSON);

          let companiesName = [];
          //get all companies name
          for (let i = 0; i < fileJSON.length; i++) {
            if (fileJSON[i][13] && !companiesName.includes(fileJSON[i][13])) {
              companiesName.push(fileJSON[i][13]);
            }
          }

          //compare arrays companiesName and companiesNameRemote
          const nonExistingCompanies = companiesName.filter(
            (company) => !companiesNameRemote.find((c) => c.name === company),
          );

          setFileJSON(fileJSON);
          setCompaniesToAdd(
            nonExistingCompanies.map((company) => ({ name: company })),
          );

          if (nonExistingCompanies.length > 0) {
            setPage(2);
          } else {
            setPage(3);
          }
        }
      } else {
        setErrorFileSend(["Veuillez selectionner un fichier"]);
      }
    }
  };

  const convertCsvToJson = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        complete: function (results) {
          resolve(results.data);
        },
        error: function (error) {
          reject(error.message);
        },
      });
    });
  };

  useEffect(() => {
    setErrorFileSend([]);
    setIsLoadingFileToSend(false);
    setCompaniesToAdd([]);
  }, [fileToSend]);

  const handlePrevious = () => {
    if (page === 3 && companiesToAdd.length > 0) {
      setPage(2);
      return;
    } else if (page === 3 && companiesToAdd.length === 0) {
      setPage(1);
      return;
    }
    setPage(page - 1);
  };

  useEffect(() => {
    if (companiesNameRemote.length > 0) {
      setCompaniesToAdd(companiesNameRemote);
    }
  }, [companiesNameRemote]);

  const handleSubmit = () => {
    if (page === 3 && Object.keys(missionsFinal).length > 0 && fileJSON) {
      setIsLoadingImportMission(true);
      const companiesArray = [
        ...companiesSelected.filter((company) => company.checked),
        ...companiesNameRemote,
      ];
      ApiDatabase.postImportMissions(
        {
          missions: missionsFinal,
          fileJSON,
          companies: companiesArray,
        },
        (res) => {
          setSlideOverDataReturn({ missions: true });
          setIsLoadingImportMission(false);
          closeSlideOver();
        },
        (err) => {
          console.log(err);
          setIsLoadingImportMission(false);
        },
      );
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    ApiDatabase.getCompanies(
      {},
      (data) => {
        if (data.companies.length > 0) {
          const companiesArray = data.companies.map((company) => ({
            name: company.name,
          }));
          setCompaniesNameRemote(companiesArray);
        } else {
          setCompaniesNameRemote([]);
        }
      },
      (err) => {
        console.log(err);
      },
    );
  }, [setCompaniesNameRemote]);

  return (
    <>
      <SlideOverHeader hide={closeSlideOver} hideShow={true}>
        {page === 1 && (
          <>
            Importation de missions
            <div className={"text-gray-500 text-sm"}>
              Importez les missions pour mettre à jour votre base de données.
            </div>
          </>
        )}
        {page === 2 && (
          <>
            Ajout d'établissements
            <div className={"text-gray-500 text-sm"}>
              Nous avons détecté
              {companiesToAdd.length > 1
                ? ` ${companiesToAdd.length} nouveaux établissements `
                : ` ${companiesToAdd.length} nouvel établissement `}
              lors de l’importation du fichier. Sélectionnez les établissements
              que vous souhaitez consulter sur Workflow.
            </div>
          </>
        )}
        {page === 3 && (
          <>
            Importation de missions
            <div className={"text-gray-500 text-sm"}>
              Importez les missions pour mettre à jour votre base de données.
            </div>
          </>
        )}
      </SlideOverHeader>
      <SlideOverBody>
        {page === 1 && (
          <SlideOverImportMissionImportFile
            fileToSend={fileToSend}
            setFileToSend={setFileToSend}
            errors={errorFileSend}
          />
        )}
        {page === 2 && (
          <SlideOverImportMissionAddCompany
            companiesToAdd={companiesToAdd}
            setCompaniesSelected={setCompaniesSelected}
            companiesSelected={companiesSelected}
          />
        )}
        {page === 3 && (
          <SlideOverImportMissionFinal
            fileData={fileJSON}
            companiesSelected={companiesSelected}
            companiesNameRemote={companiesNameRemote}
            setMissionsFinal={setMissionsFinal}
            setIsMissionToImport={setIsMissionToImport}
          />
        )}
      </SlideOverBody>
      <SlideOverFooter>
        {page === 1 && (
          <>
            <Button size={"SM"} color={"SECONDARY"} onClick={closeSlideOver}>
              Fermer
            </Button>
            {isLoadingFileToSend ? (
              <Button size={"SM"} color={"DISABLED"}>
                Confirmation ...
              </Button>
            ) : (
              <Button size={"SM"} color={"PRIMARY"} onClick={handleSubmitFile}>
                Confirmer
              </Button>
            )}
          </>
        )}
        {page === 2 && (
          <>
            <Button size={"SM"} color={"SECONDARY"} onClick={handlePrevious}>
              Retour
            </Button>
            {companiesNameRemote.length > 0 ||
            companiesSelected.filter((c) => c.checked).length > 0 ? (
              <Button size={"SM"} color={"PRIMARY"} onClick={() => setPage(3)}>
                Confirmer
              </Button>
            ) : (
              <Button size={"SM"} color={"DISABLED"}>
                Confirmer
              </Button>
            )}
          </>
        )}
        {page === 3 && (
          <>
            <Button size={"SM"} color={"SECONDARY"} onClick={handlePrevious}>
              Retour
            </Button>
            {isLoadingImportMission ? (
              <Button size={"SM"} color={"DISABLED"}>
                Confirmation ...
              </Button>
            ) : (
              <>
                {isMissionToImport ? (
                  <Button
                    size={"SM"}
                    color={"PRIMARY"}
                    onClick={() => handleSubmit()}
                  >
                    Confirmer
                  </Button>
                ) : (
                  <Button size={"SM"} color={"DISABLED"}>
                    Confirmer
                  </Button>
                )}
              </>
            )}
          </>
        )}
      </SlideOverFooter>
    </>
  );
};

export default SlideOverImportMission;
