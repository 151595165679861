import React from "react";
import Modalheader from "../../../components/header";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import Button from "../../../../button";
import useModal from "../../../useModal";

const ModalLogout = () => {
  const { toggle } = useModal();
  const handleLogout = () => {
    localStorage.removeItem("token");
    toggle();
    window.location.href = process.env.REACT_APP_LOGIN;
  };
  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Vous nous quittez déjà ?
      </Modalheader>
      {/*body*/}
      <ModalBody>
        <p>Êtes-vous sûr de vouloir vous déconnecter de Jobbiz ?</p>
      </ModalBody>
      {/*footer*/}
      <ModalFooter>
        <Button size={"LG"} color={"SECONDARY"} onClick={toggle}>
          Annuler
        </Button>
        <Button size={"LG"} onClick={handleLogout}>
          Confirmer
        </Button>
      </ModalFooter>
    </>
  );
};

export default ModalLogout;
