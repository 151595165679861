import { useEffect, useState } from "react";
import ApiDatabase from "../../server";
import StyledInput from "../styledInput/StyledInput";
import DatePicker from "react-datepicker";
import Button from "../button";
import { Calendar, CrossIcon, LoopIcon } from "../../assets/icons";

const NavBarActivity = ({
  companySelectedActivity,
  setCompanySelectedActivity,
  employeeSelectedActivity,
  setEmployeeSelectedActivity,
  actionSelected,
  setActionSelected,
  dateSelect,
  setDateSelect,
  search,
  setSearch,
}) => {
  const [companyList, setCompanyList] = useState([]);
  const [isWaitingCompanies, setIsWaitingCompanies] = useState(true);
  const [employeeList, setEmployeeList] = useState([]);
  const [isWaitingEmployees, setIsWaitingEmployees] = useState(true);
  const [actionList, setActionList] = useState([
    {
      name: "Ajout de téléphone",
      type: "tempWorkerPhoneAdd",
      checked: false,
    },
    {
      name: "Modification du téléphone",
      type: "tempWorkerPhoneUpdate",
      checked: false,
    },
    {
      name: "Ajout de pointage",
      type: "timeSheetAdd",
      checked: false,
    },
    {
      name: "Modification de pointage",
      type: "timeSheetUpdate",
      checked: false,
    },
    {
      name: "Suppression de pointage",
      type: "timeSheetDelete",
      checked: false,
    },
    {
      name: "Export de données",
      type: "exportMission",
      checked: false,
    },
  ]);

  useEffect(() => {
    const fetchCompaniesMissions = async () => {
      try {
        setIsWaitingCompanies(true);
        const getCompaniesMissionsPromise = () =>
          new Promise((resolve, reject) => {
            ApiDatabase.getCompaniesMissions(
              {},
              (data) => resolve(data),
              (err) => reject(err),
            );
          });

        const data = await getCompaniesMissionsPromise();

        if (data.companies?.length > 0) {
          setCompanyList(
            data.companies.map((company) => ({
              idCompany: company._id,
              name: company.companyName,
              checked: false,
            })),
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsWaitingCompanies(false);
      }
    };

    fetchCompaniesMissions();
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setIsWaitingEmployees(true);
        const getEmployeesPromise = () =>
          new Promise((resolve, reject) => {
            ApiDatabase.getTeams(
              {},
              (data) => resolve(data),
              (err) => reject(err),
            );
          });

        const data = await getEmployeesPromise();

        if (data.teams?.length > 0) {
          setEmployeeList(
            data.teams.map((team) => ({
              idEmployee: team._id,
              name: team.firstname + " " + team.lastname,
              checked: false,
            })),
          );
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsWaitingEmployees(false);
      }
    };

    fetchEmployees();
  }, []);

  const updateCompaniesList = (updatedCompany) => {
    const updatedCompanies = [];
    for (let i = 0; i < companyList.length; i++) {
      for (let j = 0; j < updatedCompany.length; j++) {
        if (companyList[i].idCompany === updatedCompany[j].value) {
          updatedCompanies.push({
            ...companyList[i],
            checked: updatedCompany[j].checked,
          });
        }
      }
    }

    setCompanyList(updatedCompanies);
    const selectedCompany = updatedCompanies.filter(
      (company) => company.checked,
    );

    setCompanySelectedActivity(selectedCompany);
  };

  const updateEmployeesList = (updatedEmployee) => {
    const updatedEmployees = [];
    for (let i = 0; i < employeeList.length; i++) {
      for (let j = 0; j < updatedEmployee.length; j++) {
        if (employeeList[i].idEmployee === updatedEmployee[j].value) {
          updatedEmployees.push({
            ...employeeList[i],
            checked: updatedEmployee[j].checked,
          });
        }
      }
    }
    setEmployeeList(updatedEmployees);
    const selectedEmployeeStatus = updatedEmployees.filter(
      (missionStatus) => missionStatus.checked,
    );

    setEmployeeSelectedActivity(selectedEmployeeStatus);
  };

  const updateActionList = (updatedAction) => {
    const updatedActions = [];

    for (let i = 0; i < actionList.length; i++) {
      for (let j = 0; j < updatedAction.length; j++) {
        if (actionList[i].type === updatedAction[j].value) {
          updatedActions.push({
            ...actionList[i],
            checked: updatedAction[j].checked,
          });
        }
      }
    }

    setActionList(updatedActions);

    const selectedInterimAgencies = updatedActions.filter(
      (interimAgency) => interimAgency.checked,
    );

    setActionSelected(selectedInterimAgencies);
  };

  return (
    <nav className={"w-full flex justify-between items-center min-h-[45px]"}>
      <div className={"flex gap-2 items-center text-sm"}>
        {!isWaitingCompanies ? (
          <>
            {companyList.length > 1 && (
              <div className={"max-w-[150px] text-sm"}>
                <StyledInput
                  type={"selectMultipleCheckbox"}
                  value={
                    companySelectedActivity.length > 0
                      ? companySelectedActivity
                          .map((company) => company.name)
                          .join(", ")
                      : "Entreprise"
                  }
                  list={companyList.map((company) => {
                    return {
                      value: company.idCompany,
                      label: company.name,
                      checked: company.checked,
                    };
                  })}
                  onChange={(updatedCompany) =>
                    updateCompaniesList(updatedCompany)
                  }
                  isSearchable={true}
                />
              </div>
            )}
          </>
        ) : (
          <div className={"h-12 bg-gray-200 rounded"}></div>
        )}

        <div className={"max-w-[150px] text-sm"}>
          <StyledInput
            type={"selectMultipleCheckbox"}
            value={
              actionSelected.length > 0
                ? actionSelected.map((action) => action.name).join(", ")
                : "Action"
            }
            list={actionList.map((action) => {
              return {
                value: action.type,
                label: action.name,
                checked: action.checked,
              };
            })}
            onChange={(updatedAction) => updateActionList(updatedAction)}
            isSearchable={true}
          />
        </div>
        {!isWaitingEmployees ? (
          <>
            {employeeList.length > 1 && (
              <div className={"max-w-[150px] text-sm"}>
                <StyledInput
                  type={"selectMultipleCheckbox"}
                  value={
                    employeeSelectedActivity.length > 0
                      ? employeeSelectedActivity
                          .map((employee) => employee.name)
                          .join(", ")
                      : "Utilisateur"
                  }
                  list={employeeList.map((employee) => {
                    return {
                      value: employee.idEmployee,
                      label: employee.name,
                      checked: employee.checked,
                    };
                  })}
                  onChange={(updatedEmployee) =>
                    updateEmployeesList(updatedEmployee)
                  }
                  isSearchable={true}
                />
              </div>
            )}
          </>
        ) : (
          <div className={"h-12 bg-gray-200 rounded"}></div>
        )}
        <div>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={dateSelect}
            onChange={(date) => setDateSelect(date)}
            locale="fr"
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            popperPlacement="bottom"
            className="w-full h-10 pl-3 pr-8 ft-b border rounded-lg text-center"
            customInput={
              <Button
                color={`${dateSelect ? "PRIMARY" : "SECONDARY"}`}
                size={"SMI"}
              >
                <div className={"flex gap-1"}>
                  <Calendar
                    wh={16}
                    color={dateSelect ? "#FFFFFF" : "#374151"}
                  />
                  {dateSelect && (
                    <div onClick={() => setDateSelect(null)}>
                      <CrossIcon
                        wh={16}
                        color={dateSelect ? "#FFFFFF" : "#374151"}
                      />
                    </div>
                  )}
                </div>
              </Button>
            }
            /*includeDates={dateList}*/
          />
        </div>
        <div className={"w-[213px] text-sm"}>
          <StyledInput
            type={"text"}
            placeholder={"Rechercher un objet"}
            imgFront={<LoopIcon wh={16} color={"#6B7280"} />}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default NavBarActivity;
