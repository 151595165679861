import useModal from "../../../useModal";
import Modalheader from "../../../components/header";
import React from "react";
import UseContext from "../../../../../hooks/useContext";
import ModalBody from "../../../components/body";
import {
  Cutlery,
  EmptyStateMissionTimeSheetPeriod,
  Entry,
  Exit,
  Pause,
  Play,
} from "../../../../../assets/icons";
import { durationInDecimals, floatToTime } from "../../../../../utils/Time";

const ModalLogTimeSheet = () => {
  const { toggle } = useModal();
  const { modalData, timeCut } = UseContext();

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Consulter les modifications
        <div className={"text-sm text-gray-500"}>
          Consultez les modifications effectuées par {modalData.employee} pour
          la {modalData.mission.toLowerCase()} le {modalData.date}.
        </div>
      </Modalheader>
      <ModalBody>
        <div className={"w-full h-full flex"}>
          <div
            className={
              "flex flex-col border rounded-lg rounded-r-none w-full h-[300px]"
            }
          >
            <div className={"flex flex-col h-full overflow-y-auto"}>
              {modalData.details.before.markups.length > 0 ? (
                <div className={"flex flex-col"}>
                  {modalData.details.before.markups.map((markup, index) => {
                    const filteredMarkup = Object.entries(markup).filter(
                      ([key, value]) => key === "start" || key === "end",
                    );

                    return filteredMarkup.map(([key, value], i) => {
                      if (value) {
                        const getType = () => {
                          if (key === "start") {
                            if (index === 0) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-blue-500 p-2"}
                                  >
                                    <Entry wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Entrée",
                              };
                            } else if (
                              modalData.details.before.markups[index - 1]?.end
                                ? durationInDecimals(
                                    modalData.details.before.markups[index - 1]
                                      .end,
                                    value,
                                  ) <= timeCut
                                : false
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Cutlery wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Fin de pause",
                              };
                            } else {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Play wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Fin de coupure",
                              };
                            }
                          } else if (key === "end") {
                            if (
                              index ===
                              modalData.details.before.markups.length - 1
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-blue-500 p-2"}
                                  >
                                    <Exit wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Sortie",
                              };
                            } else if (
                              modalData.details.before.markups[index + 1]?.start
                                ? durationInDecimals(
                                    value,
                                    modalData.details.before.markups[index + 1]
                                      .start,
                                  ) <= timeCut
                                : false
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Cutlery wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Début de pause",
                              };
                            } else {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Pause wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Début de coupure",
                              };
                            }
                          }
                          return { icon: "", name: "" };
                        };

                        const type = getType();

                        return (
                          <div
                            key={i}
                            className={`flex justify-between items-center p-4`}
                          >
                            <div className={"flex gap-6 items-center"}>
                              {type && <div>{type.icon}</div>}
                              {type && <div>{type.name}</div>}
                            </div>
                            <div
                              className={"flex justify-end gap-6 items-center"}
                            >
                              {value && <div>{value}</div>}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    });
                  })}
                </div>
              ) : (
                <div className={"m-auto"}>
                  <div
                    className={
                      "text-sm flex flex-col gap-1 items-center text-center max-w-[250px]"
                    }
                  >
                    <EmptyStateMissionTimeSheetPeriod
                      wh={64}
                      color={"#374151"}
                    />
                    <div className={"font-bold"}>Aucune donnée</div>
                    <div className={"text-gray-500"}>
                      Aucun pointage n’a été trouvé pour cette journée.
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                "border-t min-h-[65px] py-4 px-4 flex flex-col justify-center text-sm"
              }
            >
              <div className={"font-black"}>Avant</div>
              {modalData.details.before.totalHours !== 0 &&
                modalData.details.before.markups.length > 0 && (
                  <div className={"text-gray-500"}>
                    Total heures travaillées :{" "}
                    {floatToTime(modalData.details.before.totalHours)}
                  </div>
                )}
            </div>
          </div>
          <div
            className={
              "flex flex-col border rounded-lg rounded-l-none w-full h-[300px]"
            }
          >
            <div className={"flex flex-col h-full overflow-y-auto"}>
              {modalData.details.after.markups.length > 0 ? (
                <div className={"flex flex-col"}>
                  {modalData.details.after.markups.map((markup, index) => {
                    const filteredMarkup = Object.entries(markup).filter(
                      ([key, value]) => key === "start" || key === "end",
                    );

                    return filteredMarkup.map(([key, value], i) => {
                      if (value) {
                        const getType = () => {
                          if (key === "start") {
                            if (index === 0) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-blue-500 p-2"}
                                  >
                                    <Entry wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Entrée",
                              };
                            } else if (
                              modalData.details.after.markups[index - 1]?.end
                                ? durationInDecimals(
                                    modalData.details.after.markups[index - 1]
                                      .end,
                                    value,
                                  ) <= timeCut
                                : false
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Cutlery wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Fin de pause",
                              };
                            } else {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Play wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Fin de coupure",
                              };
                            }
                          } else if (key === "end") {
                            if (
                              index ===
                              modalData.details.after.markups.length - 1
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-blue-500 p-2"}
                                  >
                                    <Exit wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Sortie",
                              };
                            } else if (
                              modalData.details.after.markups[index + 1]?.start
                                ? durationInDecimals(
                                    value,
                                    modalData.details.after.markups[index + 1]
                                      .start,
                                  ) <= timeCut
                                : false
                            ) {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Cutlery wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Début de pause",
                              };
                            } else {
                              return {
                                icon: (
                                  <div
                                    className={"rounded-full bg-yellow-500 p-2"}
                                  >
                                    <Pause wh={20} color={"#FFFFFF"} />
                                  </div>
                                ),
                                name: "Début de coupure",
                              };
                            }
                          }
                          return { icon: "", name: "" };
                        };

                        const type = getType();

                        return (
                          <div
                            key={i}
                            className={`flex justify-between items-center p-4`}
                          >
                            <div className={"flex gap-6 items-center"}>
                              {type && <div>{type.icon}</div>}
                              {type && <div>{type.name}</div>}
                            </div>
                            <div
                              className={"flex justify-end gap-6 items-center"}
                            >
                              {value && <div>{value}</div>}
                            </div>
                          </div>
                        );
                      }
                      return null;
                    });
                  })}
                </div>
              ) : (
                <div className={"m-auto"}>
                  <div
                    className={
                      "text-sm flex flex-col gap-1 items-center text-center max-w-[250px]"
                    }
                  >
                    <EmptyStateMissionTimeSheetPeriod
                      wh={64}
                      color={"#374151"}
                    />
                    <div className={"font-bold"}>Aucune donnée</div>
                    <div className={"text-gray-500"}>
                      Aucun pointage n’a été trouvé pour cette journée.
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div
              className={
                "border-t min-h-[65px] py-4 px-4 flex flex-col justify-center text-sm"
              }
            >
              <div className={"font-black"}>Après modification</div>
              {modalData.details.after.totalHours !== 0 &&
                modalData.details.after.markups.length > 0 && (
                  <div className={"text-gray-500"}>
                    Total heures travaillées :{" "}
                    {floatToTime(modalData.details.after.totalHours)}
                  </div>
                )}
            </div>
          </div>
        </div>
      </ModalBody>
    </>
  );
};

export default ModalLogTimeSheet;
