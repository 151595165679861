import Modalheader from "../../../components/header";
import useModal from "../../../useModal";
import ModalBody from "../../../components/body";
import ModalFooter from "../../../components/footer";
import StyledInput from "../../../../styledInput/StyledInput";
import { useEffect, useState } from "react";
import Button from "../../../../button";
import { validateEmail } from "../../../../../utils/validator";
import ApiDatabase from "../../../../../server";
import UseContext from "../../../../../hooks/useContext";

const ModalModifyTeam = () => {
  const { toggle } = useModal();
  const { modalData } = UseContext();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [firstnameError, setFirstnameError] = useState(false);
  const [lastname, setLastname] = useState("");
  const [lastnameError, setLastnameError] = useState(false);
  const [isWaitingTeamInfo, setIsWaitingTeamInfo] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [errorRole, setErrorRole] = useState(false);
  const [userRole, setUserRole] = useState(200);
  const [roleList, setRoleList] = useState([
    {
      idRole: 200,
      name: "Observateur",
      checked: true,
    },
    {
      idRole: 205,
      name: "Manageur",
      checked: false,
    },
    {
      idRole: 210,
      name: "Direction",
      checked: false,
    },
  ]);

  const handleSubmit = () => {
    if (email && firstname && lastname && modalData?.idUser) {
      setFirstnameError(false);
      setLastnameError(false);
      if (!validateEmail(email)) {
        setEmailError("Veuillez entrer une adresse e-mail valide.");
      } else {
        setEmailError(false);
        setIsWaiting(true);

        if (roleList.find((role) => role.checked)?.idRole) {
          ApiDatabase.postTeamModify(
            {
              email,
              firstname,
              lastname,
              role:
                userRole === 225
                  ? userRole
                  : roleList.find((role) => role.checked).idRole,
              idUser: modalData.idUser,
            },
            (data) => {
              setIsWaiting(false);
              toggle();
            },
            (err) => {
              if (err?.response?.data?.message === "User with existing email") {
                setEmailError(
                  "Un utilisateur utilise déjà cette adresse mail.",
                );
              }
              setIsWaiting(false);
            },
          );
        } else {
          setErrorRole("Veuillez sélectionner un rôle.");
        }
      }
    } else {
      if (!email) setEmailError("Veuillez entrer une adresse e-mail.");
      else setEmailError(false);
      if (!firstname) setFirstnameError("Veuillez entrer un prénom.");
      else setFirstnameError(false);
      if (!lastname) setLastnameError("Veuillez entrer un nom.");
      else setLastnameError(false);
    }
  };

  useEffect(() => {
    if (modalData.idUser) {
      setIsWaitingTeamInfo(true);
      ApiDatabase.getTeamInfos(
        { idUser: modalData.idUser },
        (data) => {
          setIsWaitingTeamInfo(false);
          setEmail(data.user?.email || "");
          setFirstname(data.user?.firstname || "");
          setLastname(data.user?.lastname || "");
          setUserRole(data.user?.role);
          setRoleList((prevData) =>
            prevData.map((role) => ({
              ...role,
              checked: role.idRole === data.user?.role,
            })),
          );
        },
        (err) => {
          setIsWaitingTeamInfo(false);
          console.log("err", err);
        },
      );
    }
  }, [modalData]);

  const updateRolesList = (updatedRole) => {
    const value = Number(updatedRole.target.value);
    setRoleList(
      roleList.map((role) => ({
        ...role,
        checked: role.idRole === value,
      })),
    );
  };

  return (
    <>
      <Modalheader hide={toggle} hideShow={true}>
        Modifier un membre
      </Modalheader>
      <ModalBody>
        {isWaitingTeamInfo ? (
          <div className="flex flex-col gap-6 w-full animate-pulse">
            <div className={"h-20 bg-gray-200 rounded w-full"}></div>
            <div className={"flex gap-6 w-full"}>
              <div className={"h-20 bg-gray-200 rounded w-full"}></div>
              <div className={"h-20 bg-gray-200 rounded w-full"}></div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-6 w-full">
            <StyledInput
              type={"text"}
              label={"E-mail"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={emailError}
            />
            <div className={"flex gap-6 w-full"}>
              <div className={"w-full"}>
                <StyledInput
                  type={"text"}
                  label={"Prénom"}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  error={firstnameError}
                />
              </div>
              <div className={"w-full"}>
                <StyledInput
                  type={"text"}
                  label={"Nom"}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  error={lastnameError}
                />
              </div>
            </div>
            {userRole !== 225 && (
              <StyledInput
                type={"select"}
                label={"Rôle"}
                value={roleList.find((role) => role.checked)?.idRole || ""}
                list={roleList.map((role) => {
                  return {
                    value: role.idRole,
                    label: role.name,
                    checked: role.checked,
                  };
                })}
                onChange={(updatedRole) => updateRolesList(updatedRole)}
                error={errorRole}
              />
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color={"SECONDARY"} size={"LG"} onClick={toggle}>
          Annuler
        </Button>
        {isWaiting ? (
          <Button color={"DISABLED"} size={"LG"}>
            Confirmation ...
          </Button>
        ) : (
          <Button color={"PRIMARY"} size={"LG"} onClick={handleSubmit}>
            Confirmer
          </Button>
        )}
      </ModalFooter>
    </>
  );
};

export default ModalModifyTeam;
