const Badges = ({ color, size, children }) => {
  const PILL_COLOR = {
    SUCCESS: "bg-green-100 text-green-800 border border-green-200",
    INFO: "bg-blue-100 text-blue-800 border border-blue-200",
    ALERT: "bg-red-100 text-red-800 border border-red-200",
    WARNING: "bg-yellow-100 text-yellow-800 border border-yellow-200",
    DISABLED: "bg-gray-100 text-gray-800 border border-gray-200",
    PRIMARY: "bg-indigo-100 text-indigo-800 border border-indigo-200",
    SECONDARY: "bg-gray-100 text-gray-800 border border-gray-200",
  };

  const PILL_SIZE = {
    SM: "px-1 py-1",
    MD: "px-2 py-1",
    LG: "px-3 py-1",
  };

  const colorClassName = PILL_COLOR[color || "SUCCESS"];
  const sizeClassName = PILL_SIZE[size || "MD"];

  return (
    <div
      className={`rounded text-xs font-medium ${colorClassName} ${sizeClassName}`}
    >
      {children}
    </div>
  );
};

export default Badges;
