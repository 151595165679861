import React, { useEffect, useRef, useState } from "react";
import useModal from "../../modal/useModal";
import AvatarUser from "../../avatar/AvatarUser";
import { Logout } from "../../../assets/icons";
import UseContext from "../../../hooks/useContext";
import ApiDatabase from "../../../server";

const LogoutNavDesktop = () => {
  const { userData, setUserData } = UseContext();
  const { toggle } = useModal();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef();
  const [isWaiting, setIsWaiting] = useState(false);

  const handleProfil = () => {
    setDropdownOpen((prev) => !prev);
  };

  const tRole = {
    200: "Observateur",
    205: "Manageur",
    210: "Direction",
    215: "Directeur",
    225: "Administrateur",
  };

  const handleLogout = (e) => {
    e.preventDefault();
    setDropdownOpen(false);
    toggle("log_d_logout");
  };

  useEffect(() => {
    let handler = (e) => {
      if (!dropDownRef.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (userData && Object.keys(userData).length === 0) {
      setIsWaiting(true);
      ApiDatabase.getUserInfo(
        {},
        (data) => {
          if (data.user) {
            setIsWaiting(false);
            setUserData(data.user || {});
            localStorage.setItem("rUser", data.user?.role);
          } else {
            setIsWaiting(false);
            console.warn("Error: No user data");
          }
        },
        (err) => {
          console.warn(err);
        },
      );
    }
  }, [setUserData, userData]);

  return (
    <div className={"relative"} ref={dropDownRef}>
      <div
        className="w-full flex items-center justify-center cursor-pointer "
        onClick={handleProfil}
      >
        <div className={"flex gap-3"}>
          <AvatarUser size={"SM"} />
          <div className={"flex flex-col  justify-items-start"}>
            <span
              className={
                "ft-sm text-gray-700 truncate overflow-hidden max-w-[250px]"
              }
            >
              {isWaiting ? (
                <div className={"animate-pulse"}>
                  <div className={"h-4 bg-gray-200 rounded w-32"}></div>
                </div>
              ) : (
                `${userData?.firstname} ${userData?.lastname}`
              )}
            </span>
            <span className={"ft-xs text-gray-500"}>
              {isWaiting ? (
                <div className={"animate-pulse pt-1"}>
                  <div className={"h-4 bg-gray-200 rounded w-24"}></div>
                </div>
              ) : (
                tRole[userData?.role]
              )}
            </span>
          </div>
        </div>
      </div>
      {dropdownOpen && (
        <div
          className={`${dropdownOpen ? "" : "hidden"} absolute border-2 border-[#F3F4F6] rounded-lg shadow-lg h-30 w-56 top-12 right-0 z-50 bg-gray-50`}
        >
          <div
            className="flex flex-row gap-2 items-center h-9 p-2 ft-sm text-red-500 cursor-pointer"
            onClick={handleLogout}
          >
            <Logout wh={15} color={"#EF4444"} /> Déconnexion
          </div>
        </div>
      )}
    </div>
  );
};

export default LogoutNavDesktop;
