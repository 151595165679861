import { Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Layout = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tokenURL = params.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (location && navigate && location.pathname === "/") {
      const token = tokenURL || localStorage.getItem("token");

      if (token) {
        localStorage.setItem("token", token);
        window.history.replaceState({}, document.title, location.pathname);
        navigate("/hourly-tracking");
      } else {
        window.location.href = process.env.REACT_APP_LOGIN;
      }
    }
  }, [location, navigate, tokenURL]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Layout;
